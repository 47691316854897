/* eslint-disable import/no-default-export */
import { useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import { RenderLayout } from '../layouts/RenderLayout/RenderLayout';
import { ReactComponent as ShieldIcon } from '../images/Shield.svg';
import { ReactComponent as ShieldPersonIcon } from '../images/ShieldPerson.svg';
import { ReactComponent as ToggleIcon } from '../images/ToggleOn.svg';
import { ReactComponent as CheckIcon } from '../images/Check.svg';
import { ReactComponent as RegulationIcon } from '../images/Regulation.svg';
import { ReactComponent as CoinIcon } from '../images/Coin.svg';
import { PartnersSection } from '../components/CompanyPage/PartnersSection/PartnersSection';
import CEOs from '../images/CEOs.png';
import Image2 from '../images/companyPage.image2.png';

const HomePage = () => {
  useEffect(() => {
    document.title = 'Brighty app Financial and Earning app – See Your Money Grow';
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <RenderLayout
        top
        components={[
          {
            type: 'CENTERSECTION',
            title: t('companyPage.header.title'),
            subtitle: t('companyPage.header.subtitle'),
            icons: true,
          },
          {
            type: 'RIGHTIMAGESECTION',
            title: t('companyPage.section1.title'),
            subtitle: t('companyPage.section1.subtitle'),
            imageSrc: CEOs,
            list: [
              {
                text: t('companyPage.section1.listElement1.title'),
                icon: <CheckIcon />,
              },
              {
                text: t('companyPage.section1.listElement2.title'),
                icon: <CheckIcon />,
              },
            ],
          },
          {
            type: 'LEFTIMAGESECTION',
            title: t('companyPage.section2.title'),
            imageSrc: Image2,
            link: {
              text: t('companyPage.section2.caption'),
              href: '/team',
            },
          },
        ]}
      />
      <PartnersSection />
      <RenderLayout
        components={[
          {
            type: 'CARDSSECTION',
            title: t('companyPage.section4.title'),
            iconRounded: false,
            grey: true,
            cards: [
              {
                title: t('companyPage.section4.card1.title'),
                subtitle: t('companyPage.section4.card1.subtitle'),
                icon: <RegulationIcon />,
              },
              {
                title: t('companyPage.section4.card2.title'),
                subtitle: t('companyPage.section4.card2.subtitle'),
                icon: <ToggleIcon />,
              },
              {
                title: t('companyPage.section4.card3.title'),
                subtitle: t('companyPage.section4.card3.subtitle'),
                icon: <ShieldIcon />,
              },
              {
                title: t('companyPage.section4.card4.title'),
                subtitle: t('companyPage.section4.card4.subtitle'),
                icon: <ShieldPersonIcon />,
              },
              {
                title: t('companyPage.section4.card5.title'),
                subtitle: t('companyPage.section4.card5.subtitle'),
                icon: <CheckIcon />,
              },
              {
                title: t('companyPage.section4.card6.title'),
                subtitle: t('companyPage.section4.card6.subtitle'),
                icon: <CoinIcon />,
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default withTranslation()(HomePage);
