import { FC, ReactNode, useMemo } from 'react';
import styles from './Card.module.scss';
import classNames from 'classnames';
import { Typography } from '../Typography/Typography';
import { Link } from '../Link/Link';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface IProps {
  title?: string;
  subtitle?: string;
  icon?: ReactNode;
  iconRounded?: boolean;
  color?: 'white' | 'grey';
  children?: any;
  className?: any;
  description?: string;
  link?: {
    title: string;
    href: string;
  };
  caption?: string;
}

export const Card: FC<IProps> = ({
  title,
  subtitle,
  icon,
  iconRounded = true,
  color = 'grey',
  link,
  caption,
}) => {
  const isMobile = useMediaQuery('(max-width: 577px)');

  const titleVariant = useMemo(() => {
    if (iconRounded) {
      if (isMobile) {
        return 'h3';
      }
      return 'h4';
    } else {
      if (isMobile) {
        return 'h2';
      }
      return 'h3';
    }
  }, [isMobile, iconRounded]);

  return (
    <div className={classNames(styles.root, styles[`color__${color}`])}>
      <div
        className={classNames(styles.iconWrapper, {
          [styles.iconWrapper__rounded]: iconRounded && color === 'grey',
        })}
      >
        {icon}
      </div>
      <Typography variant={titleVariant}>{title}</Typography>
      {!!subtitle && (
        <Typography variant="pSmallRegular" className={styles.subtitle} color="var(--text2)">
          {subtitle}
        </Typography>
      )}
      {!!link && (
        <div className={styles.link}>
          <Link href={link.href} title={link.title} />
        </div>
      )}
      {!!caption && (
        <div className={styles.caption}>
          <Typography variant="pSmallMedium" color="var(--positive)">
            {caption}
          </Typography>
        </div>
      )}
    </div>
  );
};
