import { useEffect, useState } from 'react';
import styles from './UkModal.module.scss';
import { Typography } from '../../common/Typography/Typography';
import { useIpCountry } from '../../../api/invite';

export const UkModal = () => {
  const ipCountry = useIpCountry();
  const [open, setIsOpen] = useState<boolean>(false);
  const isUk = ipCountry.data?.country === 'GB';

  useEffect(() => setIsOpen(isUk), [isUk]);

  if (!open) {
    return null;
  }

  return (
    <div className={styles.wrapper} onClick={() => setIsOpen(false)}>
      <div className={styles.modal}>
        <div className={styles.columnCenter}>
          <Typography>
            Dear Valued Customers,
            <br />
            <br />
            Brighty has temporarily paused onboarding new clients from the UK. However, please rest
            assured that we continue to support and service our existing UK customers. <br />
            <br />
            If you are an existing customer and encounter any issues with your account or our app,
            please do not hesitate to contact us. You can reach out to our dedicated customer
            support team via email at support@brighty.app.
            <br />
            <br /> We understand the importance of seamless service and are committed to ensuring
            your experience remains uninterrupted. We promise to keep you updated on any changes
            regarding our availability in the UK market.
          </Typography>
        </div>
      </div>
    </div>
  );
};
