import styles from './NinthSection.module.scss';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import { Typography } from '../../common/Typography/Typography';

import { useTranslation } from 'react-i18next';
import DocIcon from '../../../images/Doc.svg';
import SecureIcon from '../../../images/Secure.svg';
import WalletIcon from '../../../images/Wallet.svg';
import ProfileIcon from '../../../images/Profile.svg';
import { ReactComponent as CheckIcon } from '../../../images/Check.svg';

export const NinthSection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper grey>
      <div className={styles.root}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <img src={DocIcon} />
            <div className={styles.check}>
              <CheckIcon />
            </div>
          </div>
          <div className={styles.text}>
            <Typography variant="h4">{t('indexPage.section9.listElement1.title')}</Typography>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <img src={ProfileIcon} />
            <div className={styles.check}>
              <CheckIcon />
            </div>
          </div>
          <div className={styles.text}>
            <Typography variant="h4">{t('indexPage.section9.listElement2.title')}</Typography>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <img src={SecureIcon} />
            <div className={styles.check}>
              <CheckIcon />
            </div>
          </div>
          <div className={styles.text}>
            <Typography variant="h4">{t('indexPage.section9.listElement3.title')}</Typography>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.icon}>
            <img src={WalletIcon} />
            <div className={styles.check}>
              <CheckIcon />
            </div>
          </div>
          <div className={styles.text}>
            <Typography variant="h4">{t('indexPage.section9.listElement4.title')}</Typography>
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
