import {
  ChangeEvent,
  ComponentProps,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
} from 'react';
import classNames from 'classnames';
import styles from './Input.module.scss';

export type InputProps = Omit<ComponentProps<'input'>, 'name' | 'onChange'> & {
  name: string;
  label: string;
  error?: string;
  onChange?: (name: string, event: ChangeEvent<HTMLInputElement>) => void;
};

export const Input = forwardRef(function Input(
  { label, error, onChange, ...props }: InputProps,
  outerRef: React.Ref<HTMLInputElement | null>,
) {
  const id = useId();
  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(outerRef, () => innerRef.current, []);

  const handleClick = useCallback(() => {
    innerRef.current?.focus();
  }, []);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(props.name, event);
    },
    [props.name, onChange],
  );

  useEffect(() => {
    innerRef.current?.setCustomValidity?.(error ?? '');
  }, [error]);

  return (
    <div className={classNames(styles.inputContainer, error && styles.error)} onClick={handleClick}>
      <input
        {...props}
        ref={innerRef}
        id={props.id ?? id}
        placeholder={props.placeholder ?? label}
        onChange={handleChange}
        className={classNames(styles.input, props.className)}
      />
      <label id={props.id ?? id} className={styles.placeholder}>
        {label}
      </label>
    </div>
  );
});
