export const Line = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 445 292" fill="none">
      <path
        stroke="#9356E0"
        strokeLinecap="round"
        strokeWidth="4"
        d="M2.5 290s123.5-16.762 255-105 185-183 185-183"
      />
    </svg>
  );
};
