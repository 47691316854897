import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './Section5.module.scss';

export const Section5 = () => {
  return (
    <WidthWrapper>
      <div className={styles.root}>
        <Typography variant="pLargeMedium">Investment Product Disclaimer</Typography>
        <Typography variant="pMediumRegular">
          Investment portfolio services are provided by Nodabank Corporation. Please see Nodabank
          Terms and Conditions for more details. You may be subject to an additional KYC process
          implemented by NodaBank Corporation.
        </Typography>
        <Typography variant="pMediumRegular">
          Brighty App does not perform investment portfolio services. We are not liable for any
          losses arising from your investment.
        </Typography>
        <Typography variant="pMediumRegular">
          Investing in financial products involves risk, including possible loss of principal. The
          value of investments can fluctuate, and past performance is not indicative of future
          results. Risk Disclosure:
          <ul>
            <li>Market Risk: Investments are subject to market conditions and can lose value.</li>
            <li>Liquidity Risk: Some investments may not be easily sold.</li>
            <li>Volatility Risk: Prices can be volatile and may change rapidly.</li>
            <li>Credit Risk: Issuers may default on their obligations.</li>
          </ul>
        </Typography>
        <Typography variant="pMediumRegular">
          There is no assurance that the investment objectives of the product will be achieved. The
          performance of the investment is subject to various factors, including market conditions,
          economic factors, and investment decisions made by the fund manager or issuer.
        </Typography>
        <Typography variant="pMediumRegular">
          Suitability:
          <ul>
            <li>
              This product may not be suitable for all investors. Assess your financial situation
              and seek independent financial advice if necessary. Regulatory and Tax Considerations:
            </li>
            <li>
              Understand the regulatory requirements and tax implications of your investment.
              Consult a tax advisor if needed.
            </li>
          </ul>
        </Typography>
      </div>
    </WidthWrapper>
  );
};
