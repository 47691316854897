import { FC, ReactNode } from 'react';
import styles from './SideImageSection.module.scss';
import classNames from 'classnames';
import { Image } from '../Image/Image';
import { Typography } from '../Typography/Typography';
import { ListItem } from '../ListItem/ListItem';
import { IconCaption } from '../IconCaption/IconCaption';
import { ReactComponent as CheckIcon } from '../../../images/Check.svg';
import { Link } from '../Link/Link';
import { BulletPoint } from '../BulletPoint/BulletPoint';

interface IProps {
  imageSide?: 'left' | 'right';
  src: string;
  imageClassName?: string;
  alt?: string;
  list?: {
    text: string;
    caption?: string;
    icon: ReactNode;
  }[];
  title: string;
  subtitle?: string;
  caption?: string;
  link?: {
    href: string;
    text: string;
  };
  bulletPoints?: string[];
}

export const SideImageSection: FC<IProps> = ({
  imageSide,
  src,
  list,
  imageClassName,
  alt,
  caption,
  title,
  subtitle,
  link,
  bulletPoints,
}) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles.root__reversed]: imageSide === 'right',
      })}
    >
      <div className={styles.imageColumn}>
        <Image src={src} className={imageClassName} alt={alt} />
      </div>
      <div className={styles.contentColumn}>
        <div className={styles.title}>
          <Typography variant="h2">{title}</Typography>
        </div>
        {!!subtitle && (
          <div className={styles.subtitle}>
            <Typography variant="pLargeRegular">{subtitle}</Typography>
          </div>
        )}
        {list && list.length && (
          <div className={styles.list}>
            {list.map((l) => (
              <ListItem key={l.text} text={l.text} caption={l.caption} listPoint={l.icon} />
            ))}
          </div>
        )}
        {bulletPoints && bulletPoints.length && (
          <div className={styles.bulletPoints}>
            {bulletPoints.map((b) => (
              <BulletPoint text={b} key={b} />
            ))}
          </div>
        )}
        {!!caption && (
          <IconCaption
            icon={<CheckIcon className={styles.icon} />}
            text={caption}
            color="var(--positive)"
          />
        )}
        {!!link && <Link title={link.text} href={link.href} />}
      </div>
    </div>
  );
};
