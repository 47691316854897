import { Section1 } from '../components/TonPage/Section1/Section1';
import { Section2 } from '../components/TonPage/Section2/Section2';
import { Section3 } from '../components/TonPage/Section3/Section3';
import { Section4 } from '../components/TonPage/Section4/Section4';
import { Section5 } from '../components/TonPage/Section5/Section5';

export default function TonPage() {
  return (
    <>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </>
  );
}
