import { useTranslation } from 'react-i18next';

import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './CareersSection.module.scss';
import { useEffect, useState } from 'react';

export const CareersSection = () => {
  const { t } = useTranslation();

  const [vacancies, setVacancies] = useState<string>('');

  const handleGetCareers = async () => {
    const res = await fetch('https://brighty.bamboohr.com/jobs/embed2.php?version=1.0.0', {
      method: 'GET',
    });
    const parsedRes = await res.text();
    setVacancies(parsedRes);
  };

  useEffect(() => {
    handleGetCareers();
  }, []);

  return (
    <WidthWrapper center>
      <div className={styles.root}>
        <div className={styles.title}>
          <Typography variant="h1">{t('careersPage.section1.title')}</Typography>
        </div>
        <div className={styles.subtitle}>
          <Typography variant="pLargeRegular">{t('careersPage.section1.subtitle')}</Typography>
        </div>
        <div className={styles.wrapper}>
          <div dangerouslySetInnerHTML={{ __html: vacancies }} />
        </div>
      </div>
    </WidthWrapper>
  );
};
