import { useTranslation } from 'react-i18next';
import { Typography } from '../../common/Typography/Typography';
import styles from './InterestRateSection.module.scss';
import Graphics from './Graphics.png';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';

export const InterestRateSection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper>
      <div className={styles.root}>
        <div className={styles.top}>
          <Typography variant="h2" align="center" alignMobile="center">
            {t('indexPage.interestRateSection.title')}
          </Typography>
          <Typography variant="pLargeRegular" align="center" alignMobile="center">
            {t('indexPage.interestRateSection.subtitle')}
          </Typography>
        </div>
        <img src={Graphics} alt="Graphics" className={styles.img} />
      </div>
    </WidthWrapper>
  );
};
