import styles from './Section3.module.scss';
import Picture from '../../../images/TonPage/Section3Image.png';
import Card from '../../../images/TonPage/Card.svg';
import Shield from '../../../images/Shield.svg';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import { Typography } from '../../common/Typography/Typography';

export const Section3 = () => {
  return (
    <WidthWrapper className={styles.widthWrapper}>
      <div className={styles.root}>
        <div className={styles.leftColumn}>
          <Typography variant="h1">
            Key Features
            <br />
            of the Card:
          </Typography>
          <div className={styles.row}>
            <div className={styles.smallColumn}>
              <div className={styles.iconWrapper}>
                <img src={Card} alt="" className={styles.icon} />
              </div>
              <Typography>
                Now you can easily buy, transfer, and spend your Toncoin and other cryptocurrencies
                in style with the TON-branded digital card.
              </Typography>
            </div>
            <div className={styles.smallColumn}>
              <div className={styles.iconWrapper}>
                <img src={Shield} alt="" className={styles.icon} />
              </div>
              <Typography>
                Seamlessly blending traditional banking convenience with the power of the
                crypto-economy, the Brighty card empowers you to transact securely, globally, and
                effortlessly.
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.rightColumn}>
          <img src={Picture} alt="" className={styles.image} />
        </div>
      </div>
    </WidthWrapper>
  );
};
