/* eslint-disable import/no-default-export */
import { useTranslation, withTranslation } from 'react-i18next';
import { MainSection } from '../components/TeamPage/MainSection/MainSection';

import { RenderLayout } from '../layouts/RenderLayout/RenderLayout';
import Image1 from '../images/graphics/Unicorn.png';

const TeamPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <MainSection />
      <RenderLayout
        components={[
          {
            type: 'LEFTIMAGESECTION',
            title: t('teamPage.section1.title'),
            link: {
              text: t('teamPage.section1.link'),
              href: '/careers',
            },
            imageSrc: Image1,
          },
        ]}
      />
    </>
  );
};

export default withTranslation()(TeamPage);
