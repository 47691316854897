import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './CardsSection.module.scss';
import QRcode from '../../../images/QRCode.svg';
import AppStore from '../../../images/AppStore.svg';
import GooglePlay from '../../../images/GooglePlay.svg';
import { useTranslation } from 'react-i18next';
import BrightyLetter from '../../../images/BrightyLetter.svg';

export const CardsSection = () => {
  const { t } = useTranslation();
  return (
    <WidthWrapper>
      <div className={styles.root}>
        <div className={styles.leftCard}>
          <Typography variant="h3" color="var(--text1)">
            {t('affiliatePage.cardsSection.card1.title')}
          </Typography>
        </div>
        <div className={styles.rightCard}>
          <img
            src={BrightyLetter}
            width="56px"
            height="56px"
            style={{ width: '56px', height: '56px' }}
          />
          <Typography variant="h3">{t('affiliatePage.cardsSection.card2.title')}</Typography>
          <div className={styles.qrWrapper}>
            <div className={styles.qrWrapper__column}>
              <img src={QRcode} width="160px" height="160px" />
            </div>
            <div className={styles.qrWrapper__column}>
              <div className={styles.text}>
                <div className={styles.line}>
                  <span className={styles.number}>1</span>
                  <Typography variant="pMediumMedium">{t('common.text.scanQR')}</Typography>
                </div>
                <div className={styles.line}>
                  <div className={styles.number}>2</div>
                  <Typography variant="pMediumMedium">
                    {t('common.text.downloadBrightyApp')}
                  </Typography>
                </div>
              </div>
              <div className={styles.buttons}>
                <a
                  href="https://brightyapp.go.link?adj_t=7d2bnfa&adj_redirect=https%3A%2F%2Fapps.apple.com%2Fgb%2Fapp%2Fbrighty-pay-save-and-earn%2Fid1635701696"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.button}
                >
                  <img src={AppStore} />
                </a>
                <a
                  href="https://brightyapp.go.link?adj_t=qr3gjca&adj_redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.brighty.app"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.button}
                >
                  <img src={GooglePlay} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
