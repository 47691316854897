import { FC, ReactNode } from 'react';
import styles from './ListItem.module.scss';
import { Typography } from '../Typography/Typography';

interface IProps {
  listPoint: ReactNode;
  text: string;
  caption?: string;
}

export const ListItem: FC<IProps> = ({ listPoint, text, caption }) => {
  return (
    <div className={styles.root}>
      <div className={styles.listPoint}>{listPoint}</div>
      <div className={styles.texts}>
        <Typography variant="pMediumMedium" className={styles.text}>
          {text}
        </Typography>
        {!!caption && (
          <Typography variant="pSmallRegular" className={styles.text} color="var(--text2)">
            {caption}
          </Typography>
        )}
      </div>
    </div>
  );
};
