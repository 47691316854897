import styles from './Section2.module.scss';
import Picture from '../../../images/TonPage/Section2Image.png';
import Coins from '../../../images/TonPage/Coins.svg';
import Wallet from '../../../images/Wallet.svg';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import { Typography } from '../../common/Typography/Typography';

export const Section2 = () => {
  return (
    <WidthWrapper className={styles.widthWrapper}>
      <div className={styles.root}>
        <div className={styles.leftColumn}>
          <img src={Picture} alt="" className={styles.image} />
        </div>
        <div className={styles.rightColumn}>
          <Typography variant="h1">
            Love TON?
            <br />
            Look no further
          </Typography>
          <div className={styles.row}>
            <div className={styles.smallColumn}>
              <div className={styles.iconWrapper}>
                <img src={Coins} alt="" className={styles.icon} />
              </div>
              <Typography>
                The Open Network (TON) is a decentralized and open internet, created by the
                community using a technology designed by Telegram.
              </Typography>
            </div>
            <div className={styles.smallColumn}>
              <div className={styles.iconWrapper}>
                <img src={Wallet} alt="" className={styles.icon} />
              </div>
              <Typography>
                Toncoin is TON's native token. It is used for network operations, transactions,
                games, or collectibles built on TON.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
