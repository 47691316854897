/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/dot-notation */
import { useState, useEffect, useCallback, useMemo } from 'react';
import cn from 'classnames';
// import { useThemeSwitcher } from 'react-css-theme-switcher';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';
import { Typography } from '../Typography/Typography';

import { useBool } from '../../../hooks/useBool';
import styles from './Header.module.scss';
import Close from '../../../images/Close.svg';

const localesList = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Romanian',
    value: 'ro',
  },
  {
    label: 'Lithuanian',
    value: 'lt',
  },
  {
    label: 'Latvian',
    value: 'lv',
  },
  {
    label: 'Polish',
    value: 'pl',
  },
  {
    label: 'Irish',
    value: 'ie',
  },
  // {
  //   label: 'German',
  //   value: 'de',
  // },
  // {
  //   label: 'Portuguese',
  //   value: 'pt',
  // },
];

export const Header = () => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const mobileMenuToggled = useBool();
  const activeLocale = i18n.resolvedLanguage;
  const isLanguageMenuOpen = useBool();
  const langModalOpened = useBool();
  const isBusinessPage = useMemo(() => location.pathname === '/business', [location]);

  // const mobileMenuRef = useRef<HTMLDivElement>(null);

  // useMemo(() => router.locale ?? 'en', [router.locale]);
  const { t } = useTranslation();
  const [isHeaderOnTop, setIsHeaderOnTop] = useState(true);
  // const { switcher, themes, currentTheme } = useThemeSwitcher();

  // const otherLocales = locales?.filter((locale) => locale !== activeLocale);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const HeaderColorClassName = useMemo(() => {
    if (
      [
        '/imprint',
        '/termsAndPolicies',
        '/fiat-terms-and-conditions',
        '/referral-terms-and-conditions',
        '/cryptocurrency-offering-terms-and-conditions',
        '/earning-vault-terms-and-conditions',
        '/payment-card-terms-and-conditions',
        '/invest-risk-notice',
        '/investment-offering-terms-and-conditions',
        '/privacy-policy',
        '/cookie-policy',
        '/proof-of-reserves',
      ].includes(location.pathname)
    ) {
      return 'header__grey';
    }
    return '';
  }, [location.pathname]);

  const toggleLanguageHandler = useCallback(
    (newLocaleName: any) => {
      i18n.changeLanguage(newLocaleName.value);
      localStorage.setItem('lang', newLocaleName.value);
      langModalOpened.onFalse();
    },
    [i18n],
  );

  useEffect(() => {
    const detectHeaderOnTop = () => {
      if (window.scrollY === 0) setIsHeaderOnTop(true);
      else if (window.scrollY > 0 && isHeaderOnTop) setIsHeaderOnTop(false);
    };
    detectHeaderOnTop();

    window.addEventListener('scroll', detectHeaderOnTop);
    return () => window.removeEventListener('scroll', detectHeaderOnTop);
  }, []);

  useEffect(() => {
    mobileMenuToggled.onFalse();
  }, [location.pathname]);

  const closeMobileMenu = useCallback(() => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    closeMobileMenu();
  }, [location.pathname]);

  useEffect(() => {
    if (!isMobileMenuOpen && isLanguageMenuOpen.value) {
      isLanguageMenuOpen.onFalse();
    }
  }, [isMobileMenuOpen, isLanguageMenuOpen.value]);

  useEffect(() => {
    if (mobileMenuToggled.value) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [mobileMenuToggled.value]);

  return (
    <header
      className={cn(styles.header, styles[HeaderColorClassName], {
        [styles.headerTransparent]: !isHeaderOnTop,
      })}
    >
      <nav className={cn(styles.navbar)}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <picture>
              <source srcSet="/brighty-logo.avif" type="image/avif" />
              <img src="/brighty-logo.png" alt="Brighty logo" height="48" width="48" />
            </picture>
          </Link>
        </div>
        <div className={styles.navigation}>
          <NavLink to="/" className={({ isActive }) => (isActive ? styles.navLink__active : '')}>
            <Typography variant="pLargeMedium" className={styles.navLink}>
              {t('header.links.personal')}
            </Typography>
          </NavLink>
          <NavLink
            to="/business"
            className={({ isActive }) => (isActive ? styles.navLink__active : '')}
          >
            <Typography variant="pLargeMedium" className={styles.navLink}>
              {t('header.links.business')}
            </Typography>
          </NavLink>
          <NavLink to="/invest">
            <Typography variant="pLargeMedium" className={styles.navLink__gradient}>
              AI investments
            </Typography>
          </NavLink>
          <NavLink
            to="/company"
            className={({ isActive }) => (isActive ? styles.navLink__active : '')}
          >
            <Typography variant="pLargeMedium" className={styles.navLink}>
              {t('header.links.company')}
            </Typography>
          </NavLink>
          {/* <NavLink
            to="/blog"
            className={({ isActive }) => (isActive ? styles.navLink__active : '')}
          >
            <Typography variant="pLargeMedium" className={styles.navLink}>
              {t('header.links.blog')}
            </Typography>
          </NavLink> */}
          <NavLink
            to="https://help.brighty.app/en/"
            className={({ isActive }) => (isActive ? styles.navLink__active : '')}
          >
            <Typography variant="pLargeMedium" className={styles.navLink}>
              {t('header.links.customerHelp')}
            </Typography>
          </NavLink>
        </div>
        <div className={styles.buttons}>
          {isBusinessPage ? (
            <Button
              title={t('common.button.signIn')}
              theme="primary"
              href="https://business.brighty.app/auth/signIn"
              target="_blank"
              rel="noreferrer"
            />
          ) : (
            <Button
              title={t('common.button.downloadApp')}
              theme="primary"
              href="https://app.adjust.com/qr3gjca"
              target="_blank"
              rel="noreferrer"
            />
          )}
          <Button
            className={cn(styles.langButton, styles.langButton__desktop)}
            title={activeLocale?.toUpperCase()}
            theme="secondary"
            onClick={langModalOpened.onTrue}
          />
          <button
            className={cn(styles.burgerMenu, {
              [styles.burgerMenu__toggled]: mobileMenuToggled.value,
            })}
            onClick={mobileMenuToggled.onToggle}
          >
            <div className={styles.bar} />
            <div className={styles.bar} />
          </button>
        </div>
      </nav>
      {mobileMenuToggled.value && (
        <div className={styles.overlay} onClick={mobileMenuToggled.onFalse}>
          <div className={styles.mobileMenu} onClick={(e) => e.stopPropagation()}>
            <div className={styles.navigation}>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? styles.navLink__active : '')}
              >
                <Typography variant="pLargeMedium" className={styles.navLink}>
                  {t('header.links.personal')}
                </Typography>
              </NavLink>
              <NavLink
                to="/business"
                className={({ isActive }) => (isActive ? styles.navLink__active : '')}
              >
                <Typography variant="pLargeMedium" className={styles.navLink}>
                  {t('header.links.business')}
                </Typography>
              </NavLink>
              <NavLink
                to="/invest"
                className={({ isActive }) => (isActive ? styles.navLink__active : '')}
              >
                <Typography variant="pLargeMedium" className={styles.navLink}>
                  AI investments
                </Typography>
              </NavLink>
              <NavLink
                to="/company"
                className={({ isActive }) => (isActive ? styles.navLink__active : '')}
              >
                <Typography variant="pLargeMedium" className={styles.navLink}>
                  {t('header.links.company')}
                </Typography>
              </NavLink>
              {/* <NavLink
                to="/blog"
                className={({ isActive }) => (isActive ? styles.navLink__active : '')}
              >
                <Typography variant="pLargeMedium" className={styles.navLink}>
                  {t('header.links.blog')}
                </Typography>
              </NavLink> */}
              <NavLink
                to="https://help.brighty.app/en/"
                className={({ isActive }) => (isActive ? styles.navLink__active : '')}
              >
                <Typography variant="pLargeMedium" className={styles.navLink}>
                  {t('header.links.customerHelp')}
                </Typography>
              </NavLink>
              <Button
                className={styles.langButton}
                title={activeLocale?.toUpperCase()}
                theme="secondary"
                onClick={langModalOpened.onTrue}
              />
            </div>
          </div>
        </div>
      )}
      {langModalOpened.value && (
        <div className={styles.langModalWrapper} onClick={langModalOpened.onFalse}>
          <div className={styles.langModal} onClick={(e) => e.stopPropagation()}>
            <Typography variant="h4" className={styles.langModal__title}>
              {t('common.text.selectLanguage')}
            </Typography>
            <div className={styles.list}>
              {localesList.map((l) => (
                <button
                  className={styles.listItem}
                  key={l.label}
                  onClick={toggleLanguageHandler.bind(this, l)}
                >
                  <Typography variant="pMediumRegular">{l.label}</Typography>
                </button>
              ))}
            </div>
            <button className={styles.closeButton} onClick={langModalOpened.onFalse}>
              <img src={Close} />
            </button>
          </div>
        </div>
      )}
    </header>
  );
};
