import { FC } from 'react';
import styles from './BulletPoint.module.scss';
import { Typography } from '../Typography/Typography';
import { ReactComponent as CheckIcon } from '../../../images/Check.svg';

interface IProps {
  text: string;
}

export const BulletPoint: FC<IProps> = ({ text }) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <CheckIcon />
      </div>
      <Typography variant="pMediumMedium">{text}</Typography>
    </div>
  );
};
