import { useMemo } from 'react';
import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './EuroAccountSection.module.scss';
import Ireland from './Ireland.png';
import Poland from './Poland.png';
import Latvia from './Latvia.png';
import Lithuania from './Lithuania.png';
import Romania from './Romania.png';
import { useTranslation } from 'react-i18next';

export const EuroAccountsSection = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;

  const Image = useMemo(() => {
    switch (activeLocale) {
      case 'ie':
        return Ireland;
      case 'pl':
        return Poland;
      case 'lt':
        return Lithuania;
      case 'lv':
        return Latvia;
      case 'ro':
        return Romania;
      default:
        break;
    }
  }, [activeLocale]);

  return (
    <div className={styles.root}>
      <WidthWrapper cropped className={styles.widthWrapper}>
        <div className={styles.columns}>
          <div className={styles.leftColumn}>
            <img src={Image} alt="Euro Account" />
          </div>
          <div className={styles.rightColumn}>
            <Typography variant="h2" align="center">
              {t('indexPage.euroAccountSection.title')}
            </Typography>
            <Typography
              variant="pMediumRegular"
              align="center"
              className={styles.subtitle}
              alignMobile="center"
            >
              {t('indexPage.euroAccountSection.subtitle')}
            </Typography>
          </div>
        </div>
      </WidthWrapper>
    </div>
  );
};
