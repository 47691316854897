import { useCallback, useEffect, useState } from 'react';
import { config } from '../config/config';

const authToken = btoa(config.API_KEY || '');

function request({
  url,
  method,
  body,
  signal,
  withHeaders = true,
}: {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: string;
  signal?: AbortSignal;
  withHeaders?: boolean;
}) {
  return fetch(url.startsWith('/') ? `${config.BACKEND_URL}${url}` : url, {
    body,
    method,
    signal,
    mode: 'cors',
    headers: withHeaders
      ? {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Basic ${authToken}`,
        }
      : undefined,
  });
}

export function useLightQuery<Response>(params: {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  enabled: boolean;
  withHeaders?: boolean;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<Response | undefined>(undefined);

  useEffect(() => {
    if (!params.enabled) {
      return;
    }

    const abort = new AbortController();
    request({
      url: params.url,
      method: params.method,
      signal: abort.signal,
      withHeaders: params.withHeaders,
    })
      .then((res) => {
        if (res.ok) {
          return res.json().then((parsed) => {
            setData(parsed);
            setIsLoading(false);
          });
        }

        throw new Error(res.statusText);
      })
      .catch((error: Error) => {
        setError(error);
        setIsLoading(false);
      });

    return () => abort.abort();
  }, [params.enabled, params.method, params.url, params.withHeaders]);

  return { data, error, isLoading };
}

export function useLightMutation<Response, Request>(params: {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<Response | undefined>(undefined);

  const mutate = useCallback(
    (body: Request) => {
      setIsLoading(true);
      request({
        url: params.url,
        method: params.method,
        body: JSON.stringify(body),
      })
        .then((res) =>
          res
            .json()
            .catch(() => undefined)
            .then((parsed) => {
              if (res.ok) {
                setData(parsed);
                setIsSuccess(true);
                setIsLoading(false);
              } else {
                throw new Error(parsed?.description || res.statusText);
              }
            }),
        )
        .catch((error: Error) => {
          setError(error);
          setIsSuccess(false);
          setIsLoading(false);
        });
    },
    [params.method, params.url],
  );

  return { mutate, data, error, isLoading, isSuccess };
}
