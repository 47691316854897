/* eslint-disable import/no-default-export */

import { withTranslation } from 'react-i18next';
import { HeaderSection } from '../components/AffiliatePage/HeaderSection/HeaderSection';
import { CardsSection } from '../components/AffiliatePage/CardsSection/CardsSection';
import { WhySection } from '../components/AffiliatePage/WhySection/WhySection';
import { HowSection } from '../components/AffiliatePage/HowSection/HowSection';

const AffiliatePage = () => {
  return (
    <>
      <HeaderSection />
      <CardsSection />
      <WhySection />
      <HowSection />
    </>
  );
};

export default withTranslation()(AffiliatePage);
