import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './Section3.module.scss';
import Bars from '../../../images/AIInvestPage/Bars.png';
import Bell from '../../../images/AIInvestPage/Bell.png';
import BigScreen from '../../../images/AIInvestPage/BigScreen.png';
import Globe from '../../../images/AIInvestPage/Globe.svg';
import Screen1 from '../../../images/AIInvestPage/Screen1.png';
import Screen2 from '../../../images/AIInvestPage/Screen2.png';
import Screen3 from '../../../images/AIInvestPage/Screen3.png';
import Screen4 from '../../../images/AIInvestPage/Screen4.png';
import GreyListPoint from '../../../images/AIInvestPage/GreyListPoint.svg';
import PurpleListPoint from '../../../images/AIInvestPage/PurpleListPoint.svg';
import Icon1 from '../../../images/AIInvestPage/Icon1.svg';
import Icon2 from '../../../images/AIInvestPage/Icon2.svg';
import Icon3 from '../../../images/AIInvestPage/Icon3.svg';
import Icon4 from '../../../images/AIInvestPage/Icon4.svg';

const textCards = [
  {
    title: 'No Fees:',
    subtitle: 'Enjoy commission-free investing.',
    image: Icon1,
  },
  {
    title: 'One-Click Investments:',
    subtitle: 'Invest in AI-driven strategies with a single click.',
    image: Icon2,
  },
  {
    title: 'Automated Portfolio Management:',
    subtitle: 'Let AI manage and adjust your portfolios based on market conditions.',
    image: Icon3,
  },
  {
    title: 'Instant Deposits & Withdrawals: ',
    subtitle: 'Fund your investments instantly and withdraw at any time.',
    image: Icon4,
  },
];

export const Section3 = () => {
  return (
    <WidthWrapper className={styles.wrapper}>
      <div className={styles.root}>
        <div className={styles.top}>
          <div className={styles.title}>
            <Typography variant="h1" className={styles.gradient}>
              Key
            </Typography>
            <Typography variant="h1">Features</Typography>
          </div>
        </div>
        <div className={styles.cards}>
          <div className={styles.cards__topRow}>
            <div className={styles.card}>
              <img src={Bars} alt="stack" className={styles.card__image} />
              <div className={styles.card__text}>
                <Typography className={styles.card__title}>AI-Driven Strategies:</Typography>
                <Typography className={styles.card__subtitle}>
                  Choose from portfolios crafted by AI with up to 21% in the last year performance.
                </Typography>
              </div>
            </div>
            <div className={styles.card}>
              <img src={Bell} alt="cactus" className={styles.card__image} />
              <div className={styles.card__text}>
                <Typography className={styles.card__title}>Market Insights:</Typography>
                <Typography className={styles.card__subtitle}>
                  AI analyzes millions of data points to create the best investment opportunities.
                </Typography>
              </div>
            </div>
          </div>
          <div className={styles.card__big}>
            <div className={styles.card__big__column1}>
              <div className={styles.card__big__top}>
                <img src={Globe} alt="globe" className={styles.card__big__top__image} />
                <div className={styles.text}>
                  <Typography variant="h4" className={styles.card__big__title}>
                    Diverse Sectors
                  </Typography>
                  <Typography variant="pLargeRegular" className={styles.card__big__subtitle}>
                    Invest in strategies across:
                  </Typography>
                </div>
              </div>
              <div className={styles.lists}>
                <div className={styles.list}>
                  <div className={styles.list__item}>
                    <img src={PurpleListPoint} alt="" />
                    NASDAQ
                  </div>
                  <div className={styles.list__item}>
                    <img src={PurpleListPoint} alt="" />
                    S&P 500
                  </div>
                  <div className={styles.list__item}>
                    <img src={PurpleListPoint} alt="" />
                    Momentum
                  </div>
                  <div className={styles.list__item}>
                    <img src={PurpleListPoint} alt="" />
                    Industrial
                  </div>
                </div>
                <div className={styles.list}>
                  <div className={styles.list__item}>
                    <img src={GreyListPoint} alt="" />
                    Dividends
                  </div>
                  <div className={styles.list__item}>
                    <img src={GreyListPoint} alt="" />
                    Real Estate
                  </div>
                  <div className={styles.list__item}>
                    <img src={GreyListPoint} alt="" />
                    Energy
                  </div>
                  <div className={styles.list__item}>and more</div>
                </div>
              </div>
            </div>
            <img src={BigScreen} className={styles.bigScreen} />
            <div className={styles.screens}>
              <img src={Screen1} className={styles.screen1} />
              <img src={Screen2} className={styles.screen2} />
              <img src={Screen3} className={styles.screen3} />
              <img src={Screen4} className={styles.screen4} />
            </div>
          </div>
        </div>
        <div className={styles.textCards}>
          {textCards.map((card) => (
            <div className={styles.textCard} key={card.title}>
              <img src={card.image} alt="" className={styles.textCard__image} />
              <div className={styles.textCard__texts}>
                <Typography variant="h4" className={styles.textCard__title}>
                  {card.title}
                </Typography>
                <Typography variant="pLargeRegular" className={styles.textCard__subtitle}>
                  {card.subtitle}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </WidthWrapper>
  );
};
