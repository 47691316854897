/* eslint-disable import/no-default-export */
import { useEffect, useMemo } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { RenderLayout } from '../layouts/RenderLayout/RenderLayout';

import { ReactComponent as CoinIcon } from '../images/Coin.svg';
import { ReactComponent as InvestIcon } from '../images/Invest.svg';
import { ReactComponent as WalletIcon } from '../images/Wallet.svg';
import Image1 from '../images/graphics/MoneyCube.png';
import Image4 from '../images/graphics/Postbox.png';
import Image8 from '../images/indexPage.image8.png';
import { SmallCardsSection } from '../components/HomePage/SmallCardsSection/SmallCardsSection';
import { SignUpSection } from '../components/HomePageWithLocales/SignUpSection/SignUpSection';
import { EuroAccountsSection } from '../components/HomePageWithLocales/EuroAccountSection/EuroAccountSection';
import { RatesAndFeesSection } from '../components/HomePageWithLocales/RatesAndFeesSection/RatesAndFeesSection';
import { InterestRateSection } from '../components/HomePageWithLocales/InterestRateSection/InterestRateSection';
import { RewardReferralSection } from '../components/HomePageWithLocales/RewardReferralSection/RewardReferralSection';
import { DownloadSection } from '../components/HomePageWithLocales/DownloadSection/DownloadSection';
import IrelandBar from '../images/IrelandBar.png';
import RomaniaBar from '../images/RomaniaBar.png';
import Poland from '../images/PolandBar.png';
import LithuaniaBar from '../images/LithuaniaBar.png';
import LatviaBar from '../images/LatviaBar.png';

const HomePageWithLocales = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;

  useEffect(() => {
    document.title = 'Brighty app Financial and Earning app – See Your Money Grow';
  }, []);

  const HeaderImage = useMemo(() => {
    if (activeLocale === 'ie') {
      return IrelandBar;
    } else if (activeLocale === 'ro') {
      return RomaniaBar;
    } else if (activeLocale === 'pl') {
      return Poland;
    } else if (activeLocale === 'lt') {
      return LithuaniaBar;
    } else if (activeLocale === 'lv') {
      return LatviaBar;
    } else {
      return Image1;
    }
  }, [activeLocale]);

  return (
    <>
      <RenderLayout
        top
        components={[
          {
            type: 'CENTERSECTION',
            title: t('indexPage.header.title'),
            subtitle: t('indexPage.header.subtitle'),
            imageSrc: HeaderImage,
            withQR: true,
          },
        ]}
      />
      <SignUpSection />
      <RenderLayout
        top
        components={[
          {
            type: 'CENTERSECTION',
            title: t('indexPage.section9.title'),
            subtitle: t('indexPage.section9.subtitle'),
            imageSrc: Image8,
            component: <SmallCardsSection />,
          },
          {
            type: 'RIGHTIMAGESECTION',
            title: t('indexPage.section3.title'),
            subtitle: t('indexPage.section3.subtitle'),
            caption: t('indexPage.section3.caption'),
            imageSrc: Image4,
            list: [
              {
                text: t('indexPage.section3.listElement1.title'),
                caption: t('indexPage.section3.listElement1.subtitle'),
                icon: <WalletIcon />,
              },
              {
                text: t('indexPage.section3.listElement2.title'),
                caption: t('indexPage.section3.listElement2.subtitle'),
                icon: <InvestIcon />,
              },
              {
                text: t('indexPage.section3.listElement3.title'),
                caption: t('indexPage.section3.listElement3.subtitle'),
                icon: <CoinIcon />,
              },
            ],
          },
        ]}
      />
      <EuroAccountsSection />
      <RatesAndFeesSection />
      <InterestRateSection />
      <RewardReferralSection />
      <DownloadSection />
    </>
  );
};

export default withTranslation()(HomePageWithLocales);
