import { FC, ReactNode } from 'react';
import { WidthWrapper } from '../../components/common/WidthWrapper/WidthWrapper';
import { Button } from '../../components/common/Button/Button';
import { Image } from '../../components/common/Image/Image';
import { Typography } from '../../components/common/Typography/Typography';
import styles from './RenderLayout.module.scss';
import { SideImageSection } from '../../components/common/SideImageSection/SideImageSection';
import { Card } from '../../components/common/Card/Card';
import classNames from 'classnames';
import SSAIcon from '../../images/SSA.svg';
import VQFImage from '../../images/VQF.png';
import FCISImage from '../../images/FCIS.png';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import QRcode from '../../images/QRCode.svg';
import { useTranslation } from 'react-i18next';

import AppStore from '../../images/AppStore.svg';
import GooglePlay from '../../images/GooglePlay.svg';

interface IProps {
  top?: boolean;
  components: IComponent[];
}

type IComponent = IHeaderComponent | ILeftImageComponent | IRightImageComponent | ICardsComponent;

interface IHeaderComponent {
  type: 'CENTERSECTION';
  title: string;
  subtitle?: string | null;
  caption?: string;
  buttonText?: string;
  buttonAction?: (() => void) | string;
  secondButtonText?: string;
  secondButtonAction?: (() => void) | string;
  imageSrc?: string;
  bottomImageSrc?: string;
  bottomText?: string;
  bottomButtonText?: string;
  bottomButtonAction?: (() => void) | string;
  icons?: boolean;
  withQR?: boolean;
  titleFontSize?: any;
  fullSize?: boolean;
  component?: ReactNode;
}

interface ILeftImageComponent {
  type: 'LEFTIMAGESECTION';
  title: string;
  subtitle?: string;
  caption?: string;
  buttonText?: string;
  buttonAction?: () => void | string;
  imageSrc: string;
  list?: {
    text: string;
    caption?: string;
    icon: ReactNode;
  }[];
  link?: {
    text: string;
    href: string;
  };
  bulletPoints?: string[];
}

interface IRightImageComponent {
  type: 'RIGHTIMAGESECTION';
  title: string;
  subtitle?: string;
  caption?: string;
  buttonText?: string;
  buttonAction?: () => void | string;
  imageSrc: string;
  list?: {
    text: string;
    caption?: string;
    icon: ReactNode;
  }[];
  link?: {
    text: string;
    href: string;
  };
  bulletPoints?: string[];
}

interface ICardsComponent {
  type: 'CARDSSECTION';
  title?: string;
  subtitle?: string;
  caption?: string;
  buttonText?: string;
  grey?: boolean;
  iconRounded?: boolean;
  buttonAction?: () => void;
  cards?: {
    title: string;
    subtitle?: string;
    icon: ReactNode;
    link?: {
      title: string;
      href: string;
    };
    caption?: string;
  }[];
}

export const RenderLayout: FC<IProps> = ({ components, top }) => {
  const isMobile = useMediaQuery('(max-width: 577px)');
  const { t } = useTranslation();

  const renderComponent = (component: IComponent) => {
    switch (component.type) {
      case 'CENTERSECTION':
        return (
          <WidthWrapper center cropped key={component.title}>
            <div className={styles.header}>
              <div
                className={classNames(styles.textWrapper, {
                  [styles.textWrapper__fullSize]: component.fullSize,
                })}
              >
                <div className={styles.title}>
                  <Typography
                    variant={component.titleFontSize ? component.titleFontSize : 'h1'}
                    align="center"
                  >
                    {component.title}
                  </Typography>
                </div>
                {!!component.subtitle && (
                  <div className={styles.subtitle}>
                    <Typography variant="pLargeRegular">{component.subtitle}</Typography>
                  </div>
                )}
                {!!component.buttonText && (
                  <div className={styles.button}>
                    {typeof component.buttonAction === 'string' ? (
                      <>
                        <Button
                          href={component.buttonAction}
                          target="_blank"
                          rel="noreferrer"
                          title={component.buttonText}
                        />
                        {!!component.secondButtonText &&
                          typeof component.secondButtonAction === 'string' && (
                            <Button
                              href={component.secondButtonAction}
                              target="_blank"
                              rel="noreferrer"
                              title={component.secondButtonText}
                              theme="secondary"
                            />
                          )}
                      </>
                    ) : (
                      <>
                        {component.buttonAction ? (
                          <Button title={component.buttonText} onClick={component.buttonAction} />
                        ) : null}
                        {!!component.secondButtonText &&
                          typeof component.secondButtonAction === 'string' && (
                            <Button
                              href={component.secondButtonAction}
                              target="_blank"
                              rel="noreferrer"
                              title={component.secondButtonText}
                              theme="secondary"
                            />
                          )}
                        {!!component.secondButtonText &&
                          typeof component.secondButtonAction === 'function' && (
                            <Button
                              title={component.secondButtonText}
                              onClick={component.secondButtonAction}
                              theme="secondary"
                            />
                          )}
                      </>
                    )}
                  </div>
                )}
              </div>
              {!!component.withQR && (
                <div className={styles.qrWrapper}>
                  <div className={styles.qrWrapper__column}>
                    <img src={QRcode} width="160px" height="160px" />
                  </div>
                  <div className={styles.qrWrapper__column}>
                    <Typography variant="pMediumMedium">
                      {t('common.text.scanQRAndDownload')}
                    </Typography>
                    <a
                      href="https://brightyapp.go.link?adj_t=7d2bnfa&adj_redirect=https%3A%2F%2Fapps.apple.com%2Fgb%2Fapp%2Fbrighty-pay-save-and-earn%2Fid1635701696"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={AppStore} />
                    </a>
                    <a
                      href="https://brightyapp.go.link?adj_t=qr3gjca&adj_redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.brighty.app"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={GooglePlay} />
                    </a>
                  </div>
                </div>
              )}
              {!!component.imageSrc && <Image className={styles.image} src={component.imageSrc} />}
              {(!!component.bottomImageSrc ||
                component.bottomText ||
                component.bottomButtonText) && (
                <div className={styles.bottom}>
                  <div className={styles.earn}>
                    <img src={component.bottomImageSrc} />
                    <Typography variant="pMediumMedium">{component.bottomText}</Typography>
                  </div>
                  {typeof component.bottomButtonAction === 'string' ? (
                    <Button
                      href={component.bottomButtonAction}
                      target="_blank"
                      rel="noreferrer"
                      title={component.bottomButtonText}
                      theme="black"
                    />
                  ) : null}
                  {typeof component.bottomButtonAction === 'function' ? (
                    <Button
                      title={component.bottomButtonText}
                      theme="black"
                      onClick={component.bottomButtonAction}
                    />
                  ) : null}
                </div>
              )}
              {component.icons && (
                <div className={styles.icons}>
                  <img
                    src={VQFImage}
                    alt="Verein zur Qualitätssicherung von Finanzdienstleistungen"
                  />
                  <img src={SSAIcon} alt="Swiss Startup Association" />
                  <img src={FCISImage} alt="Finansinių Nusikaltimų Tyrimo Tarnyba" />
                </div>
              )}
              {component.component}
            </div>
          </WidthWrapper>
        );
      case 'RIGHTIMAGESECTION':
        return (
          <WidthWrapper key={component.title}>
            <div className={styles.rightImageSection}>
              <SideImageSection
                src={component.imageSrc}
                imageSide="right"
                imageClassName={styles.image}
                list={component.list}
                title={component.title}
                subtitle={component.subtitle}
                caption={component.caption}
                link={component.link}
                bulletPoints={component.bulletPoints}
              />
            </div>
          </WidthWrapper>
        );
      case 'LEFTIMAGESECTION':
        return (
          <WidthWrapper key={component.title}>
            <div className={styles.leftImageSection}>
              <SideImageSection
                src={component.imageSrc}
                imageSide="left"
                imageClassName={styles.image}
                list={component.list}
                title={component.title}
                subtitle={component.subtitle}
                caption={component.caption}
                link={component.link}
                bulletPoints={component.bulletPoints}
              />
            </div>
          </WidthWrapper>
        );
      case 'CARDSSECTION':
        return (
          <WidthWrapper grey={component.grey} key={component.title}>
            <div className={styles.cardsSection}>
              {component.title && (
                <Typography variant="h2" align={isMobile ? 'left' : 'center'}>
                  {component.title}
                </Typography>
              )}
              <div className={styles.cards}>
                {component.cards?.map((card) => (
                  <Card
                    icon={card.icon}
                    title={card.title}
                    key={card.title}
                    link={card.link}
                    subtitle={card.subtitle}
                    color={component.grey ? 'white' : 'grey'}
                    iconRounded={component.iconRounded}
                    caption={card.caption}
                  />
                ))}
              </div>
            </div>
          </WidthWrapper>
        );
      default:
        break;
    }
  };

  return (
    <div
      className={classNames({
        [styles.top]: top,
      })}
    >
      {components.map((component) => renderComponent(component))}
    </div>
  );
};
