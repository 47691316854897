import { Line } from './Line';
import styles from './Calculator.module.scss';
import { Typography } from '../../../common/Typography/Typography';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Buttons = [
  {
    value: 2000,
    label: '2k',
    perMonth: '$250',
  },
  {
    value: 10000,
    label: '10k',
    perMonth: '$1,250',
  },
  {
    value: 100000,
    label: '100k',
    perMonth: '$12,500',
  },
  {
    value: 1000000,
    label: '1M',
    perMonth: '$125,000',
  },
  {
    value: 10000000,
    label: '10M',
    perMonth: '$1,250,000',
  },
];

export const Calculator = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<{ value: number; label: string; perMonth: string }>(
    Buttons[2],
  );

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <div className={styles.values}>
          <div className={styles.column}>
            <Typography variant="h3" align="center" alignMobile="center" color="var(--tertiary)">
              {selected.label}
            </Typography>
            <Typography
              variant="pSmallRegular"
              color="var(--text2)"
              align="center"
              alignMobile="center"
            >
              {t('affiliatePage.chart.followerBase')}
            </Typography>
          </div>
          <div className={styles.column}>
            <Typography variant="h3" align="center" alignMobile="center" color="var(--tertiary)">
              {selected.perMonth}
            </Typography>
            <Typography
              variant="pSmallRegular"
              color="var(--text2)"
              align="center"
              alignMobile="center"
            >
              {t('affiliatePage.chart.perMonth')}
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.chart}>
        <div className={styles.line}>
          <Line />
        </div>
        <hr
          className={classNames(styles.dottedline, styles[`dottedline_position_${selected.value}`])}
        />
        <div className={classNames(styles.circle, styles[`circle_position_${selected.value}`])} />
      </div>
      <div className={styles.bottom}>
        <div className={styles.buttons}>
          {Buttons.map((b) => (
            <button
              key={b.value}
              className={classNames(styles.button, {
                [styles.button_selected]: b.value === selected.value,
              })}
              onClick={() => setSelected(b)}
            >
              <Typography
                variant="captionMedium"
                color={b.value === selected.value ? 'var(--white)' : 'var(--text2)'}
                align="center"
              >
                {b.label}
              </Typography>
            </button>
          ))}
        </div>
        <Typography
          variant="captionRegular"
          color="var(--text2)"
          align="center"
          alignMobile="center"
        >
          {t('affiliatePage.chart.legend')}
        </Typography>
      </div>
    </div>
  );
};
