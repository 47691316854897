import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Typography } from '../Typography/Typography';
import styles from './CountrySelect.module.scss';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { useBool } from '../../../hooks/useBool';
import { ReactComponent as ChevronRightIcon } from '../../../images/ChevronRight.svg';
import SearchIcon from '../../../images/Search.svg';
import { getCountryLabel, getCountryName, countryCodes } from '../../../utils/country';

function CountrySelectItem({
  value,
  callingCode,
  onSelect,
}: {
  value: string;
  callingCode?: boolean;
  onSelect: (value: string) => void;
}) {
  const handleClick = useCallback(() => onSelect(value), [onSelect, value]);
  const label = useMemo(
    () => (value ? getCountryLabel(value, { name: true, emoji: true, callingCode }) : ''),
    [callingCode, value],
  );

  return (
    <div className={styles.searchListItem} onClick={handleClick}>
      <Typography variant="pMediumMedium">{label}</Typography>
    </div>
  );
}

export function CountrySelect({
  value,
  label,
  callingCode,
  className,
  onChange,
}: {
  value: string | undefined;
  label?: string;
  callingCode?: boolean;
  className?: string;
  onChange: (value: string) => void;
}) {
  const isOpen = useBool();
  const countryRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  useOutsideClick([countryRef], isOpen.onFalse);

  const foundCountries = useMemo(() => {
    const query = searchValue.trim().toLowerCase();
    if (!query) {
      return countryCodes;
    }

    return countryCodes.filter((code) => getCountryName(code).toLowerCase().includes(query));
  }, [searchValue]);

  const handleChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const handleSelect = useCallback(
    (nextValue: string) => {
      onChange(nextValue);
      isOpen.onFalse();
    },
    [isOpen, onChange],
  );

  const handleClickInputWrapper = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (!isOpen.value) {
      setSearchValue('');
    }
  }, [isOpen.value]);

  const textValue = useMemo(
    () => (value ? getCountryLabel(value, { name: !callingCode, emoji: true, callingCode }) : ''),
    [callingCode, value],
  );

  const isFilled = !!value;

  return (
    <div
      ref={countryRef}
      className={classNames(styles.inputContainer, className)}
      onClick={isOpen.onTrue}
    >
      {label ? (
        <Typography
          className={classNames(styles.placeholder, {
            [styles.placeholder__focused]: !isFilled,
          })}
          variant="pSmallRegular"
          color="var(--text2)"
        >
          {label}
        </Typography>
      ) : null}

      <Typography
        className={classNames(styles.value, {
          [styles.value__focused]: !isFilled,
        })}
        variant={'pMediumRegular'}
        color={isFilled ? 'var(--text0)' : 'var(--secondary)'}
      >
        {textValue}
      </Typography>
      <div
        className={classNames(styles.iconContainer, {
          [styles.iconContainer__rotated]: isOpen.value,
          [styles.iconContainer__filled]: isFilled,
        })}
      >
        <ChevronRightIcon />
      </div>
      {isOpen.value && (
        <div className={styles.dropdown} onClick={(e) => e.stopPropagation()}>
          <div className={styles.searchInputContainer} onClick={handleClickInputWrapper}>
            <img src={SearchIcon} alt="" />
            <input
              ref={inputRef}
              className={styles.searchInput}
              placeholder="Search"
              onChange={handleChangeSearch}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className={styles.searchList}>
            {foundCountries.map((code: any) => (
              <CountrySelectItem
                key={code}
                value={code}
                callingCode={callingCode}
                onSelect={handleSelect}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
