import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './SignUpSection.module.scss';
import Phone from './Phone.png';
import { Button } from '../../common/Button/Button';
import { useTranslation } from 'react-i18next';

export const SignUpSection = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.line}>
        <WidthWrapper cropped className={styles.wrapper}>
          <div className={styles.columns}>
            <div className={styles.leftColumn}>
              <Typography variant="h2" className={styles.title} align="center" alignMobile="center">
                {t('indexPage.signUpSection.title')}
              </Typography>
              <hr className={styles.dashedLine} />
              <Typography className={styles.subtitle} variant="pLargeRegular">
                {t('indexPage.signUpSection.subtitle')}
              </Typography>
              <Button
                color="accent"
                title="Sign up"
                size="large"
                href="https://app.adjust.com/qr3gjca"
                target="_blank"
              />
            </div>
            <div className={styles.rightColumn}>
              <img src={Phone} alt="Phone" className={styles.phone} />
            </div>
          </div>
        </WidthWrapper>
      </div>
    </div>
  );
};
