import { FC } from 'react';
import styles from './Image.module.scss';
import classNames from 'classnames';

interface IProps {
  src?: string;
  width?: number;
  height?: number;
  className?: string;
  alt?: string;
}

export const Image: FC<IProps> = ({ src, width, height, className, alt = '' }) => {
  return (
    <img
      src={src}
      width={width}
      height={height}
      alt={alt}
      className={classNames(styles.root, className)}
    />
  );
};
