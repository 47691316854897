import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './WhySection.module.scss';
import { Button } from '../../common/Button/Button';
import CommunityIcon from '../../../images/CommunityPlain.svg';
import RegulationIcon from '../../../images/Regulation.svg';
import Miner from '../../../images/Miner.png';
import { ReactComponent as CheckIcon } from '../../../images/Check.svg';
import { useTranslation } from 'react-i18next';
import Bee from '../../../images/graphics/Bee.png';
import Unicorn from '../../../images/graphics/Unicorn.png';
import Banknotes from '../../../images/graphics/Banknotes.png';

const cards = [
  {
    icon: Bee,
    title: 'affiliatePage.whySection.cards.card1.title',
    subtitle: 'affiliatePage.whySection.cards.card1.subtitle',
  },
  {
    icon: Unicorn,
    title: 'affiliatePage.whySection.cards.card2.title',
    subtitle: 'affiliatePage.whySection.cards.card2.subtitle',
  },
  {
    icon: Banknotes,
    title: 'affiliatePage.whySection.cards.card3.title',
    subtitle: 'affiliatePage.whySection.cards.card3.subtitle',
  },
];

export const WhySection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper>
      <div className={styles.root}>
        <div className={styles.top}>
          <Typography variant="h2" align="center">
            {t('affiliatePage.whySection.title')}
          </Typography>
          <Typography align="center">{t('affiliatePage.whySection.subtitle')}</Typography>
        </div>
        <div className={styles.cards}>
          {cards.map((card) => (
            <div className={styles.card} key={card.title}>
              <img src={card.icon} className={styles.card__icon} />
              <div className={styles.card__text}>
                <Typography variant="h3" align="left" color="var(--fill5)">
                  {t(card.title)}
                </Typography>
                <Typography align="left" variant="pSmallRegular" color="var(--fill5)">
                  {t(card.subtitle)}
                </Typography>
              </div>
            </div>
          ))}
        </div>
        <Button
          title={t('affiliatePage.whySection.button')}
          size="large"
          href="http://app.impact.com/campaign-campaign-info-v2/Razz-Finanzz-AG.brand?io=sf3nNPqamGgQm%2FbMzJlGWG%2FEUqHP0wTCbHjwRzVXLNkCNGoMCb%2FvQaN1IytrjPFZ"
          target="_blank"
        />
        <div className={styles.icons}>
          <div className={styles.item}>
            <div className={styles.icon}>
              <img src={RegulationIcon} />
              <div className={styles.check}>
                <CheckIcon />
              </div>
            </div>
            <div className={styles.text}>
              <Typography variant="h4" align="center">
                {t('affiliatePage.whySection.points.point1.title')}
              </Typography>
              <Typography align="center" variant="pSmallRegular" color="var(--text2)">
                {t('affiliatePage.whySection.points.point1.subtitle')}
              </Typography>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.icon}>
              <img src={CommunityIcon} />
              <div className={styles.check}>
                <CheckIcon />
              </div>
            </div>
            <div className={styles.text}>
              <Typography variant="h4" align="center">
                {t('affiliatePage.whySection.points.point2.title')}
              </Typography>
              <Typography align="center" variant="pSmallRegular" color="var(--text2)">
                {t('affiliatePage.whySection.points.point2.subtitle')}
              </Typography>
            </div>
          </div>
          <div className={styles.item}>
            <img src={Miner} style={{ width: '72px' }} />
            <div className={styles.text}>
              <Typography variant="h4" align="center">
                {t('affiliatePage.whySection.points.point3.title')}
              </Typography>
              <Typography align="center" variant="pSmallRegular" color="var(--text2)">
                {t('affiliatePage.whySection.points.point3.subtitle')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
