import { useTranslation } from 'react-i18next';
import { RenderLayout } from '../layouts/RenderLayout/RenderLayout';

import { ReactComponent as CoinIcon } from '../images/Coin.svg';
import { ReactComponent as WalletIcon } from '../images/Wallet.svg';
import { ReactComponent as ReceiveIcon } from '../images/Receive.svg';
import { ReactComponent as TransactionIcon } from '../images/Transaction.svg';
import { ReactComponent as ShieldIcon } from '../images/Shield.svg';
import { ReactComponent as ShieldPersonIcon } from '../images/ShieldPerson.svg';
import Image4 from '../images/graphics/Postbox.png';
import Image1 from '../images/businessPage.image1.png';
import Image3 from '../images/businessPage.image3.png';
import { FourthSection } from '../components/BusinessPage/FourthSection/FourthSection';
import { useBool } from '../hooks/useBool';
import { BusinessModal } from '../components/common/Modals/BusinessModal/BusinessModal';

export const BusinessPage = () => {
  const { t } = useTranslation();
  const modalOpened = useBool();

  return (
    <>
      <RenderLayout
        top
        components={[
          {
            type: 'CENTERSECTION',
            title: t('businessPage.header.title'),
            buttonText: t('businessPage.header.button'),
            secondButtonText: t('common.button.signIn'),
            secondButtonAction: 'https://business.brighty.app/auth/signIn',
            buttonAction: modalOpened.onTrue,
            imageSrc: Image1,
            titleFontSize: 'h2',
            fullSize: true,
          },
          {
            type: 'CARDSSECTION',
            title: t('businessPage.section1.title'),
            cards: [
              {
                title: t('businessPage.section1.text1'),
                icon: <WalletIcon />,
              },
              {
                title: t('businessPage.section1.text2'),
                icon: <TransactionIcon />,
              },
              {
                title: t('businessPage.section1.text3'),
                icon: <CoinIcon />,
              },
            ],
          },
          {
            type: 'RIGHTIMAGESECTION',
            title: t('businessPage.section2.title'),
            imageSrc: Image4,
            caption: t('businessPage.section2.caption'),
            list: [
              {
                text: t('businessPage.section2.listElement1.title'),
                caption: t('businessPage.section2.listElement1.subtitle'),
                icon: <ReceiveIcon />,
              },
              {
                text: t('businessPage.section2.listElement2.title'),
                icon: <CoinIcon />,
              },
              {
                text: t('businessPage.section2.listElement3.title'),
                caption: t('businessPage.section2.listElement3.subtitle'),
                icon: <TransactionIcon />,
              },
              {
                text: t('businessPage.section2.listElement4.title'),
                icon: <ShieldIcon />,
              },
            ],
          },
          {
            type: 'LEFTIMAGESECTION',
            title: t('businessPage.section5.title'),
            subtitle: t('businessPage.section5.subtitle'),
            imageSrc: Image3,
            list: [
              {
                text: t('businessPage.section5.listElement1.title'),
                icon: <WalletIcon />,
              },
              {
                text: t('businessPage.section5.listElement2.title'),
                icon: <TransactionIcon />,
              },
            ],
          },
          // {
          //   type: 'LEFTIMAGESECTION',
          //   title: t('businessPage.section3.title'),
          //   subtitle: t('businessPage.section3.subtitle'),
          //   imageSrc: '',
          //   list: [
          //     {
          //       text: t('businessPage.section3.listElement1.title'),
          //       icon: <SendIcon />,
          //     },
          //     {
          //       text: t('businessPage.section3.listElement2.title'),
          //       icon: <ShieldIcon />,
          //     },
          //   ],
          // },
          // {
          //   type: 'RIGHTIMAGESECTION',
          //   title: t('businessPage.section4.title'),
          //   subtitle: t('businessPage.section4.subtitle'),
          //   imageSrc: '',
          //   list: [
          //     {
          //       text: t('businessPage.section4.listElement1.title'),
          //       icon: <TransactionIcon />,
          //     },
          //     {
          //       text: t('businessPage.section4.listElement2.title'),
          //       icon: <BrightyIcon />,
          //     },
          //   ],
          // },
          // {
          //   type: 'LEFTIMAGESECTION',
          //   title: t('businessPage.section5.title'),
          //   imageSrc: '',
          //   list: [
          //     {
          //       text: t('businessPage.section5.listElement1.title'),
          //       icon: <WalletIcon />,
          //     },
          //     {
          //       text: t('businessPage.section5.listElement2.title'),
          //       icon: <TransactionIcon />,
          //     },
          //   ],
          // },
        ]}
      />
      <FourthSection />
      <RenderLayout
        components={[
          {
            type: 'CARDSSECTION',
            iconRounded: false,
            cards: [
              {
                title: t('businessPage.section6.card1.title'),
                icon: <WalletIcon />,
              },
              {
                title: t('businessPage.section6.card2.title'),
                icon: <ShieldIcon />,
              },
              {
                title: t('businessPage.section6.card3.title'),
                icon: <ShieldPersonIcon />,
              },
            ],
          },
        ]}
      />
      <BusinessModal open={modalOpened.value} onClose={modalOpened.onFalse} />
    </>
  );
};
