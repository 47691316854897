import { useTranslation } from 'react-i18next';

import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './InfoSection.module.scss';

export const InfoSection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper>
      <div className={styles.root}>
        <div className={styles.block}>
          <Typography variant="pMediumRegular">{t('proofOfReservesPage.text1')}</Typography>
        </div>
        <div className={styles.block}>
          <Typography variant="h4">{t('proofOfReservesPage.text2.title')}</Typography>
          <Typography variant="pMediumRegular">
            {t('proofOfReservesPage.text2.subtitle')}
          </Typography>
        </div>
        <div className={styles.block}>
          <Typography variant="h4">{t('proofOfReservesPage.text3.title')}</Typography>
          <Typography variant="pMediumRegular">
            {t('proofOfReservesPage.text3.subtitle')}
          </Typography>
        </div>
      </div>
    </WidthWrapper>
  );
};
