import { useTranslation } from 'react-i18next';

import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './ReviewSection.module.scss';
import IliaEllipse from '../../../images/IliaEllipse.png';
import JaveriaEllipse from '../../../images/JaveriaEllipse.png';
import ArslanEllipse from '../../../images/ArslanEllipse.png';

export const ReviewSection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper>
      <div className={styles.root}>
        <Typography variant="h2" className={styles.title}>
          {t('careersPage.section3.title')}
        </Typography>
        <Typography variant="pLargeRegular" className={styles.subtitle}>
          {t('careersPage.section3.subtitle')}
        </Typography>
        <div className={styles.cards}>
          <div className={styles.card}>
            <img src={IliaEllipse} alt="Ilia" className={styles.image} />
            <Typography variant="pMediumMedium">
              What motivates me is my teammates. Surrounded by people who really care about what
              they build. Given respect and trust to make what you really will use, without red
              tape. This is how it should be.
            </Typography>
            <Typography variant="pMediumMedium" className={styles.name}>
              Ilia
            </Typography>
          </div>
          <div className={styles.card}>
            <img src={JaveriaEllipse} alt="Javeria" className={styles.image} />
            <Typography variant="pMediumMedium">
              Excellent decision after my graduation. Supportive environment of highly skilled
              individuals. Working on product that I firmly believe in. Given opportunity to gain
              additional accreditation and skill set.
            </Typography>
            <Typography variant="pMediumMedium" className={styles.name}>
              Javeria
            </Typography>
          </div>
          <div className={styles.card}>
            <img src={ArslanEllipse} alt="Arslan" className={styles.image} />
            <Typography variant="pMediumMedium">
              The great battle is on repeat here daily. We want to own our money, in all its forms,
              we want the same for our customers, and we reflect this focus in challenging the
              industry and each other, incessantly.
            </Typography>
            <Typography variant="pMediumMedium" className={styles.name}>
              Arslan
            </Typography>
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
