import styles from './Section4.module.scss';
import Phone from '../../../images/AIInvestPage/Phone.png';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import { Typography } from '../../common/Typography/Typography';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import CardStack from '../../../images/AIInvestPage/Card-stack.png';

export const Section4 = () => {
  const isMobile = useMediaQuery('(max-width: 577px)');

  if (isMobile) {
    return (
      <div className={styles.root}>
        <WidthWrapper className={styles.wrapper}>
          <div className={styles.columns}>
            <div className={styles.top}>
              <div className={styles.title}>
                <Typography variant="h1" className={styles.title__gradient}>
                  Join
                </Typography>
                <Typography variant="h1">Brighty</Typography>
              </div>
              <Typography
                variant="pLargeRegular"
                className={styles.subtitle}
                align="center"
                alignMobile="center"
              >
                Start Investing Today!
              </Typography>
            </div>
            <div className={styles.text}>
              <Typography variant="pLargeRegular" className={styles.subtitle} align="left">
                Ready to revolutionize your investment strategy?
              </Typography>
              <Typography variant="pLargeRegular">
                <b>
                  Download the Brighty app, complete the onboarding process, and let our AI handle
                  the rest. Achieve financial growth effortlessly and efficiently.
                </b>
              </Typography>
            </div>
            <img src={CardStack} className={styles.cardStack} />
            <img src={Phone} alt="Phone" className={styles.phone} />
            <div className={styles.buttons}>
              <a
                href="https://brightyapp.go.link?adj_t=1f3b3an8&adj_campaign=AI_Investpage"
                target="_blank"
                rel="noreferrer"
              >
                <button className={styles.downloadButton}>Download the App</button>
              </a>
              <a
                href="https://brightyapp.go.link?adj_t=1f3b3an8&adj_campaign=AI_Investpage"
                target="_blank"
                rel="noreferrer"
              >
                <button className={styles.startButton}>Start Investing Now</button>
              </a>
            </div>
          </div>
        </WidthWrapper>
      </div>
    );
  } else {
    return (
      <div className={styles.root}>
        <WidthWrapper className={styles.wrapper}>
          <div className={styles.top}>
            <div className={styles.title}>
              <Typography variant="h1" className={styles.title__gradient}>
                Join
              </Typography>
              <Typography variant="h1">Brighty</Typography>
            </div>
            <Typography variant="pLargeRegular" className={styles.subtitle} align="center">
              Start Investing Today!
            </Typography>
          </div>
          <div className={styles.columns}>
            <div className={styles.leftColumn}>
              <div className={styles.text}>
                <Typography variant="pLargeRegular" className={styles.subtitle} align="left">
                  Ready to revolutionize your investment strategy?
                </Typography>
                <Typography variant="pLargeRegular">
                  <b>
                    Download the Brighty app, complete the onboarding process, and let our AI handle
                    the rest. Achieve financial growth effortlessly and efficiently.
                  </b>
                </Typography>
              </div>
              <div className={styles.buttons}>
                <a
                  href="https://brightyapp.go.link?adj_t=1f3b3an8&adj_campaign=AI_Investpage"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={styles.downloadButton}>Download the App</button>
                </a>
                <a
                  href="https://brightyapp.go.link?adj_t=1f3b3an8&adj_campaign=AI_Investpage"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={styles.startButton}>Start Investing Now</button>
                </a>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <img src={CardStack} className={styles.cardStack} />
              <img src={Phone} alt="Phone" className={styles.phone} />
            </div>
            {/* <div className={classNames(styles.badge, styles.badge1)}>
            <GreenArrow />
            <span>14%</span>
          </div>
          <div className={classNames(styles.badge, styles.badge2)}>
            <GreenArrow />
            <span>16%</span>
          </div> */}
          </div>
        </WidthWrapper>
      </div>
    );
  }
};
