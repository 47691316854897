import { useTranslation } from 'react-i18next';

import { RenderLayout } from '../layouts/RenderLayout/RenderLayout';
import { CareersSection } from '../components/CareersPage/CareersSection/CareersSection';
import { ReactComponent as AppearanceIcon } from '../images/Appearance.svg';
import { ReactComponent as FriendsIcon } from '../images/Friends.svg';
import { ReactComponent as ToggleIcon } from '../images/ToggleOn.svg';
import { FraudSection } from '../components/CareersPage/FraudSection/FraudSection';
import { ReviewSection } from '../components/CareersPage/ReviewSection/ReviewSection';
import Image2 from '../images/graphics/Piper.png';

export const CareersPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <RenderLayout
        top
        components={[
          {
            type: 'CENTERSECTION',
            title: t('careersPage.header.title'),
            subtitle: t('careersPage.header.subtitle'),
            imageSrc: '',
          },
        ]}
      />
      <CareersSection />
      <RenderLayout
        components={[
          {
            type: 'RIGHTIMAGESECTION',
            title: t('careersPage.section2.title'),
            subtitle: t('careersPage.section2.subtitle'),
            imageSrc: Image2,
            bulletPoints: [
              t('careersPage.section2.bulletPoint1'),
              t('careersPage.section2.bulletPoint2'),
              t('careersPage.section2.bulletPoint3'),
              t('careersPage.section2.bulletPoint4'),
              t('careersPage.section2.bulletPoint5'),
              t('careersPage.section2.bulletPoint6'),
              t('careersPage.section2.bulletPoint7'),
            ],
          },
        ]}
      />
      <ReviewSection />
      <RenderLayout
        components={[
          {
            type: 'CARDSSECTION',
            title: t('careersPage.section4.title'),
            subtitle: t('careersPage.section4.subtitle'),
            grey: true,
            cards: [
              {
                title: t('careersPage.section4.card1.title'),
                subtitle: t('careersPage.section4.card1.subtitle'),
                icon: <FriendsIcon />,
              },
              {
                title: t('careersPage.section4.card2.title'),
                subtitle: t('careersPage.section4.card2.subtitle'),
                icon: <AppearanceIcon />,
              },
              {
                title: t('careersPage.section4.card3.title'),
                subtitle: t('careersPage.section4.card3.subtitle'),
                icon: <ToggleIcon />,
              },
            ],
          },
        ]}
      />
      <FraudSection />
    </>
  );
};
