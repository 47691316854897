declare global {
  interface Window {
    fbq: (...args: unknown[]) => void;
    gtag: (...args: unknown[]) => void;
  }
}

export function activateAnalytics() {
  if (typeof window.gtag === 'function') {
    window.gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  }

  if (typeof window.fbq === 'function') {
    window.fbq('consent', 'grant');
  }
}
