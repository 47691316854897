import styles from './Section1.module.scss';
import Card from '../../../images/TonPage/Card.png';
import NewLogo from '../../../images/TonPage/NewLogo.svg';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import { Typography } from '../../common/Typography/Typography';

export const Section1 = () => {
  return (
    <WidthWrapper className={styles.wrapper}>
      <div className={styles.root}>
        <div className={styles.text}>
          <Typography variant="h1" className={styles.title} align="center" alignMobile="center">
            Brighty TON&nbsp;
            <img src={NewLogo} alt="NewLogo" className={styles.newLogo} />
          </Typography>
          <Typography variant="h1" className={styles.title} align="center" alignMobile="center">
            Crypto Card
          </Typography>
        </div>
        <img src={Card} alt="Card" className={styles.card} />
      </div>
    </WidthWrapper>
  );
};
