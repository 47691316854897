import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './FourthSection.module.scss';
import { ReactComponent as WalletIcon } from '../../../images/Wallet.svg';

import { useTranslation } from 'react-i18next';
import { Typography } from '../../common/Typography/Typography';
import { ReactComponent as TransactionIcon } from '../../../images/Transaction.svg';
import { ReactComponent as SendIcon } from '../../../images/Send.svg';
import { ReactComponent as ShieldIcon } from '../../../images/Shield.svg';
import { ReactComponent as ShieldPersonIcon } from '../../../images/ShieldPerson.svg';

export const FourthSection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper>
      <div className={styles.root}>
        <div className={styles.card}>
          <WalletIcon />
          <div className={styles.cardText}>
            <Typography variant="h3">{t('businessPage.section4.card1.title')}</Typography>
            <Typography variant="pLargeRegular">
              {t('businessPage.section4.card1.subtitle')}
            </Typography>
          </div>
          <div className={styles.points}>
            <div className={styles.point}>
              <div className={styles.iconWrapper}>
                <SendIcon />
              </div>
              <Typography variant="pMediumMedium">
                {t('businessPage.section4.card2.point1')}
              </Typography>
            </div>
            <div className={styles.point}>
              <div className={styles.iconWrapper}>
                <ShieldIcon />
              </div>
              <Typography variant="pMediumMedium">
                {t('businessPage.section4.card2.point2')}
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <ShieldPersonIcon />
          <div className={styles.cardText}>
            <Typography variant="h3">{t('businessPage.section4.card2.title')}</Typography>
            <Typography variant="pLargeRegular">
              {t('businessPage.section4.card2.subtitle')}
            </Typography>
          </div>
          <div className={styles.points}>
            <div className={styles.point}>
              <div className={styles.iconWrapper}>
                <TransactionIcon />
              </div>
              <Typography variant="pMediumMedium">
                {t('businessPage.section4.card1.point1')}
              </Typography>
            </div>
            <div className={styles.point}>
              <div className={styles.iconWrapper}>
                <picture>
                  <source srcSet="/brighty-logo.avif" type="image/avif" />
                  <img src="/brighty-logo.png" alt="Brighty logo" height="48" width="48" />
                </picture>
              </div>
              <Typography variant="pMediumMedium">
                {t('businessPage.section4.card1.point2')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
