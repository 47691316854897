import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { hideCookieBannerURLs } from '../../../const/hideCookieBannerURLs';
import { useBool } from '../../../hooks/useBool';

import { activateAnalytics } from '../../../utils/activateAnalytics';
import { eraseCookie } from '../../../utils/removeCookies';
import { Popup } from '../../Popups/Popup/Popup';
import { Button } from '../Button/Button';
import { Spacer } from '../Spacer/Spacer';
import { Typography } from '../Typography/Typography';
import styles from './CookieBanner.module.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

function acceptAllCookies() {
  try {
    window.localStorage.setItem('ACCEPTED_COOKIES', 'Analytics');
  } catch (error) {
    console.error(error);
  }

  activateAnalytics();
}

const SettingsModal: FC<IProps> = ({ isOpen, onClose }) => {
  const [cookies, setCookies] = useState<string[]>(['Analytics']);
  const { t } = useTranslation();

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (cookies.find((c) => c === e.target.name)) {
      setCookies((prev) => prev.filter((c) => c !== e.target.name));
    } else {
      setCookies((prev) => [...prev, e.target.name]);
    }
  };

  const saveSettings = () => {
    if (!cookies.includes('Analytics')) {
      eraseCookie('_ga');
      eraseCookie('_gat');
      eraseCookie('_gid');
    }
    window.localStorage.setItem('ACCEPTED_COOKIES', cookies.join(','));
    onClose();
  };

  const handleClose = () => {
    acceptAllCookies();
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      containerSectionClass={styles.settingsModal}
      showCloseButton={true}
    >
      <Typography variant="h3">{t('cookieBanner.settings')}</Typography>
      <Spacer h={24} />
      <Typography variant="pSmallRegular">{t('cookieBanner.text')}</Typography>
      <div className={styles.inputs}>
        <label className={styles.pseudoCheckboxLabel} htmlFor="settingCheckboxRequired">
          <input
            id="settingCheckboxRequired"
            className={styles.pseudoCheckboxInput}
            type="checkbox"
            name="Analytics"
            defaultChecked={true}
            // onChange={() => {}}
            required
            disabled
          />
          <span className={styles.pseudoCheckbox} />
          <div className={styles.pseudoCheckboxTexts}>
            <Typography className={styles.pseudoCheckboxTitle} variant="pSmallSemibold">
              Strictly required
            </Typography>
            <Typography className={styles.pseudoCheckboxText} variant="pSmallRegular">
              Cookies that are necessary to enable basic website functions.
            </Typography>
          </div>
        </label>
        <label className={styles.pseudoCheckboxLabel} htmlFor="settingCheckboxAnalytics">
          <input
            id="settingCheckboxAnalytics"
            className={styles.pseudoCheckboxInput}
            type="checkbox"
            name="Analytics"
            defaultChecked={true}
            onChange={handleToggle}
            required
          />
          <span className={styles.pseudoCheckbox} />
          <div className={styles.pseudoCheckboxTexts}>
            <Typography className={styles.pseudoCheckboxTitle} variant="pSmallSemibold">
              Analytics
            </Typography>
            <Typography className={styles.pseudoCheckboxText} variant="pSmallRegular">
              These cookies help us understand how this website works, how visitors interact with
              the website and if there are any technical problems.
            </Typography>
          </div>
        </label>
      </div>
      <div className={styles.settingsButtons}>
        <Button
          title="Accept all cookies"
          className={styles.button}
          theme="black"
          onClick={handleClose}
        />
        <Button
          title="Save settings"
          className={styles.button}
          onClick={saveSettings}
          theme="black"
        />
      </div>
    </Popup>
  );
};

export const CookieBanner = () => {
  const shouldOpen = useBool(() => {
    try {
      return !window.localStorage.getItem('ACCEPTED_COOKIES');
    } catch {
      return true;
    }
  });

  const settingsModalIsOpen = useBool(false);
  const location = useLocation();
  const { t } = useTranslation();
  const shouldHideOnPP = useMemo(
    () => hideCookieBannerURLs.find((url) => url === location.pathname),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    try {
      const ACCEPTED_COOKIES = window.localStorage.getItem('ACCEPTED_COOKIES');
      if (ACCEPTED_COOKIES?.includes('Analytics')) {
        activateAnalytics();
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleClose = () => {
    acceptAllCookies();
    shouldOpen.onFalse();
  };

  const manageCookies = () => {
    handleClose();
    settingsModalIsOpen.onTrue();
  };

  if (shouldHideOnPP) {
    return null;
  }

  return (
    <>
      {shouldOpen.value && (
        <div className={styles.root}>
          <Typography variant="pSmallMedium" className={styles.text}>
            {t('cookieBanner.text')}
          </Typography>
          <div className={styles.buttons}>
            <Button onClick={handleClose} className={styles.buttons__button} theme="black">
              <Typography className={styles.buttonText}>{t('cookieBanner.acceptAll')}</Typography>
            </Button>
            <Button onClick={manageCookies} className={styles.buttons__button} theme="black">
              <Typography className={styles.buttonText}>{t('cookieBanner.manage')}</Typography>
            </Button>
          </div>
          {/* <CloseButton onClick={handleClose} sectionClass={styles.closeButton} /> */}
        </div>
      )}
      {settingsModalIsOpen.value && (
        <SettingsModal isOpen={settingsModalIsOpen.value} onClose={settingsModalIsOpen.onFalse} />
      )}
    </>
  );
};
