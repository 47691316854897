import { useCallback, useEffect, useState } from 'react';
import {
  CountryCode,
  isValidPhoneNumber,
  formatIncompletePhoneNumber,
  parsePhoneNumberWithError,
} from 'libphonenumber-js/mobile';
import { useIpCountry } from '../../../api/invite';
import { CountrySelect } from '../CountrySelect/CountrySelect';
import { Input } from '../Input/Input';
import styles from './PhoneInput.module.scss';

export function PhoneInput({
  label,
  autoFocus,
  onChange,
}: {
  label: string;
  autoFocus?: boolean;
  onChange: (value: string) => void;
}) {
  const ipCountryQuery = useIpCountry();
  const [phone, setPhone] = useState<string>('');
  const [country, setCountry] = useState<string | undefined>();
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

  const nextCountry = ipCountryQuery.data?.country;
  useEffect(() => {
    if (nextCountry) {
      setCountry((prevCountry) => prevCountry ?? nextCountry);
    }
  }, [nextCountry]);

  const handlePhoneChange = useCallback(
    (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
      const filtered = event.target.value.replace(/[^+-\d\s\(\)]/g, '');
      if (event.target.selectionStart === filtered.length) {
        try {
          const parsed = parsePhoneNumberWithError(filtered, country as CountryCode);
          const nextIsValid = parsed.isValid();
          if (nextIsValid) {
            const nextCountry = parsed.country ?? (country as CountryCode);
            const nextPhone = formatIncompletePhoneNumber(parsed.nationalNumber, nextCountry);
            setPhone(nextPhone);
            setCountry(nextCountry);
            setIsValid(nextIsValid);
          } else {
            const nextPhone = formatIncompletePhoneNumber(filtered, country as CountryCode);
            setPhone(nextPhone);
            setIsValid(nextIsValid);
          }

          onChange(parsed.number);
        } catch {
          const nextPhone = formatIncompletePhoneNumber(filtered, country as CountryCode);
          setPhone(nextPhone);
          setIsValid(false);
        }
      } else {
        setPhone(filtered);
        setIsValid(isValidPhoneNumber(filtered, country as CountryCode));
      }
    },
    [country, onChange],
  );

  return (
    <div className={styles.wrapper}>
      <CountrySelect className={styles.country} value={country} onChange={setCountry} callingCode />
      <Input
        type="tel"
        name="phone"
        label={label}
        autoFocus={autoFocus}
        autoComplete="tel-national"
        value={phone}
        error={isValid || !phone ? undefined : 'Invalid phone number'}
        onChange={handlePhoneChange}
      />
    </div>
  );
}
