import styles from './Section1.module.scss';
import Phone from '../../../images/AIInvestPage/Phone.png';
import CardStack from '../../../images/AIInvestPage/Card-stack.png';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import { Typography } from '../../common/Typography/Typography';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

export const Section1 = () => {
  const isMobile = useMediaQuery('(max-width: 577px)');

  if (isMobile) {
    return (
      <div className={styles.root}>
        <WidthWrapper className={styles.wrapper}>
          <div className={styles.columns}>
            <div className={styles.titles}>
              <Typography variant="h1" className={styles.title} alignMobile="center">
                Maximize Your Wealth
              </Typography>
              <Typography variant="h1" className={styles.title__gradient} alignMobile="center">
                with AI Investments
              </Typography>
            </div>
            <img src={CardStack} className={styles.cardStack} />
            <img src={Phone} alt="" className={styles.phone} />
            <div className={styles.buttons}>
              <button className={styles.downloadButton}>Download the App</button>
              <button className={styles.startButton}>Start Investing Now</button>
            </div>
          </div>
        </WidthWrapper>
      </div>
    );
  } else {
    return (
      <div className={styles.root}>
        <WidthWrapper className={styles.wrapper}>
          <div className={styles.columns}>
            <div className={styles.leftColumn}>
              <div className={styles.titles}>
                <Typography variant="h1" className={styles.title} align="left">
                  Maximize Your Wealth
                </Typography>
                <Typography variant="h1" className={styles.title__gradient} align="left">
                  with AI Investments
                </Typography>
              </div>
              <div className={styles.buttons}>
                <a
                  href="https://brightyapp.go.link?adj_t=1f3b3an8&adj_campaign=AI_Investpage"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={styles.downloadButton}>Download the App</button>
                </a>
                <a
                  href="https://brightyapp.go.link?adj_t=1f3b3an8&adj_campaign=AI_Investpage"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={styles.startButton}>Start Investing Now</button>
                </a>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <img src={CardStack} className={styles.cardStack} />
              <img src={Phone} alt="Phone" className={styles.phone} />
            </div>
            {/* <div className={classNames(styles.badge, styles.badge1)}>
            <GreenArrow />
            <span>14%</span>
          </div>
          <div className={classNames(styles.badge, styles.badge2)}>
            <GreenArrow />
            <span>16%</span>
          </div> */}
          </div>
        </WidthWrapper>
      </div>
    );
  }
};
