import { Button } from '../../common/Button/Button';
import { Typography } from '../../common/Typography/Typography';
import styles from './HeaderSection.module.scss';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import { Image } from '../../common/Image/Image';
import Pumpkin from '../../../images/Pumpkin.png';
import { useCallback } from 'react';

export function HeaderSection({ message }: { message?: string }) {
  const handleContactUs = useCallback(() => {
    if ('Intercom' in window && typeof window.Intercom === 'function') {
      try {
        window.Intercom('showNewMessage');
      } catch {}
    }
  }, []);

  return (
    <WidthWrapper cropped>
      <div className={styles.root}>
        <Typography variant="h1" className={styles.title}>
          {message || '404'}
        </Typography>
        <div className={styles.button}>
          <Button title="Home" href="/" />
          <Button title="Contact us" onClick={handleContactUs} />
        </div>
        <Image src={Pumpkin} alt="" width={800} height={525} />
      </div>
    </WidthWrapper>
  );
}
