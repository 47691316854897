import classNames from 'classnames';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './SeventhSection.module.scss';
import { Image } from '../../common/Image/Image';
import { Typography } from '../../common/Typography/Typography';

import { useTranslation } from 'react-i18next';
import { Link } from '../../common/Link/Link';
import ShieldIcon from '../../../images/Shield.svg';
import CheckIcon from '../../../images/Check.svg';
import Image7 from '../../../images/indexPage.image7.png';

export const SeventhSection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper>
      <div className={styles.row}>
        <div className={classNames(styles.root, styles.root__reversed)}>
          <div className={styles.imageColumn}>
            <Image src={Image7} className={styles.img} />
          </div>
          <div className={styles.contentColumn}>
            <div className={styles.title}>
              <Typography variant="h2">{t('indexPage.section7.title')}</Typography>
            </div>
            <div className={styles.row}>
              <div className={styles.item}>
                <div className={styles.icon}>
                  <picture>
                    <source srcSet="/brighty-logo.avif" type="image/avif" />
                    <img src="/brighty-logo.png" alt="Brighty logo" height="48" width="48" />
                  </picture>
                </div>
                <Typography variant="pMediumMedium">
                  {t('indexPage.section7.listElement1.title')}
                </Typography>
              </div>
              <div className={styles.item}>
                <div className={styles.icon}>
                  <img src={ShieldIcon} />
                </div>
                <Typography variant="pMediumMedium">
                  {t('indexPage.section7.listElement2.title')}
                </Typography>
              </div>
              <div className={styles.item}>
                <div className={styles.icon}>
                  <img src={CheckIcon} />
                </div>
                <Typography variant="pMediumMedium">
                  {t('indexPage.section7.listElement3.title')}
                </Typography>
              </div>
            </div>
            <Link title={t('indexPage.section7.caption')} href="/company" />
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
