import { useTranslation } from 'react-i18next';
import { ReactComponent as Brighty } from '../../../images/BrightyLetter.svg';
import { ReactComponent as LinkIcon } from '../../../images/Link.svg';
import { ReactComponent as EarnIcon } from '../../../images/Earn2.svg';
import { ReactComponent as ProfileIcon } from '../../../images/Profile.svg';
import { ReactComponent as EUIcon } from '../../../images/EU.svg';
import { Typography } from '../../common/Typography/Typography';
import styles from './HowSection.module.scss';
import { Card } from '../../common/Card/Card';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';

export const HowSection = () => {
  const { t } = useTranslation();
  return (
    <WidthWrapper>
      <div className={styles.root}>
        <Typography variant="h2" align="center">
          {t('affiliatePage.howSection.title')}
        </Typography>
        <div className={styles.cards}>
          <Card
            icon={<Brighty />}
            title={t('affiliatePage.howSection.cards.card1.title')}
            subtitle={t('affiliatePage.howSection.cards.card1.subtitle')}
            link={{
              title: t('affiliatePage.howSection.cards.card1.link'),
              href: 'http://app.impact.com/campaign-campaign-info-v2/Razz-Finanzz-AG.brand?io=sf3nNPqamGgQm%2FbMzJlGWG%2FEUqHP0wTCbHjwRzVXLNkCNGoMCb%2FvQaN1IytrjPFZ',
            }}
          />
          <Card
            icon={<LinkIcon />}
            title={t('affiliatePage.howSection.cards.card2.title')}
            subtitle={t('affiliatePage.howSection.cards.card2.subtitle')}
          />
          <Card
            icon={<EarnIcon />}
            title={t('affiliatePage.howSection.cards.card3.title')}
            subtitle={t('affiliatePage.howSection.cards.card3.subtitle')}
          />
        </div>
        <div className={styles.bottom}>
          <Typography variant="h4">{t('affiliatePage.howSection.points.title')}</Typography>
          <div className={styles.list}>
            <div className={styles.listItem}>
              <ProfileIcon width={24} height={24} />
              <Typography className={styles.listItemText}>
                {t('affiliatePage.howSection.points.point1')}
              </Typography>
            </div>
            <div className={styles.listItem}>
              <EUIcon width={24} height={24} />
              <Typography className={styles.listItemText}>
                {t('affiliatePage.howSection.points.point2')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
