import { SyntheticEvent, useCallback, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Header } from '../components/common/Header/Header';

const ReferralTermsAndConditionsPage = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleLoad = useCallback((event: SyntheticEvent<HTMLIFrameElement>) => {
    const iframe = event.currentTarget;
    iframe.style.height = iframe?.contentDocument?.body.scrollHeight + 'px';
  }, []);

  return (
    <>
      <Header />
      <iframe
        ref={iframeRef}
        src={`/referral-terms-and-conditions.html`}
        style={{ border: 'none', width: '100%' }}
        onLoad={handleLoad}
      />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default withTranslation()(ReferralTermsAndConditionsPage);
