/* eslint-disable import/no-default-export */

import { useTranslation } from 'react-i18next';
import { RenderLayout } from '../layouts/RenderLayout/RenderLayout';
import GooglePay from '../images/GooglePay.png';

const GooglePayPage = () => {
  const { t } = useTranslation();

  return (
    <RenderLayout
      top
      components={[
        {
          type: 'CENTERSECTION',
          title: t('googlePayPage.header.title'),
          subtitle: t('googlePayPage.header.subtitle'),
          imageSrc: GooglePay,
          fullSize: true,
        },
      ]}
    />
  );
};

export default GooglePayPage;
