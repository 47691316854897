import { registerLocale, getName, getAlpha2Codes } from 'i18n-iso-countries';
import enCountryNames from 'i18n-iso-countries/langs/en.json';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js/mobile';

registerLocale(enCountryNames);

export const countryCodes = Object.keys(getAlpha2Codes()).filter((code) => {
  try {
    getCountryCallingCode(code as CountryCode);
    return true;
  } catch {
    return false;
  }
});

export function getCountryEmoji(countryCode: string) {
  if (countryCode.length !== 2) {
    return undefined;
  }

  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));

  return String.fromCodePoint(...codePoints);
}

export function getCountryName(countryCode: string) {
  return getName(countryCode, 'en') ?? countryCode;
}

export { getCountryCallingCode };

export function getCountryLabel(
  countryCode: string,
  { name = true, emoji = true, callingCode = false } = {},
) {
  return [
    name && getCountryName(countryCode),
    emoji && getCountryEmoji(countryCode),
    callingCode && '+' + getCountryCallingCode(countryCode as CountryCode),
  ]
    .filter(Boolean)
    .join(' ');
}
