import { useTranslation } from 'react-i18next';
import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './DownloadSection.module.scss';
import Graphics from './Graphics.png';
import QRcode from '../../../images/QRCode.svg';
import AppStore from '../../../images/AppStore.svg';
import GooglePlay from '../../../images/GooglePlay.svg';

export const DownloadSection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper className={styles.widthWrapper}>
      <div className={styles.root}>
        <div className={styles.columns}>
          <div className={styles.leftColumn}>
            <Typography variant="h2" alignMobile="center">
              {t('indexPage.downloadSection.title')}
            </Typography>
            <Typography variant="pMediumRegular" alignMobile="center">
              {t('indexPage.downloadSection.subtitle')}
            </Typography>
            <div className={styles.qrWrapper}>
              <div className={styles.qrWrapper__column}>
                <img src={QRcode} width="160px" height="160px" />
              </div>
              <div className={styles.qrWrapper__column}>
                <Typography variant="pMediumMedium">{t('indexPage.qrCode')}</Typography>
                <a
                  href="https://brightyapp.go.link?adj_t=7d2bnfa&adj_redirect=https%3A%2F%2Fapps.apple.com%2Fgb%2Fapp%2Fbrighty-pay-save-and-earn%2Fid1635701696"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={AppStore} />
                </a>
                <a
                  href="https://brightyapp.go.link?adj_t=qr3gjca&adj_redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.brighty.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={GooglePlay} />
                </a>
              </div>
            </div>
          </div>
          <img src={Graphics} className={styles.img} />
        </div>
      </div>
    </WidthWrapper>
  );
};
