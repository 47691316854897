import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './RatesAndFeesSection.module.scss';

import { useTranslation } from 'react-i18next';
import { Typography } from '../../common/Typography/Typography';
import { ReactComponent as TransactionIcon } from '../../../images/Transaction.svg';
import { ReactComponent as ProfileIcon } from '../../../images/Profile.svg';

export const RatesAndFeesSection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper>
      <div className={styles.root}>
        <Typography variant="h2" align="center">
          {t('indexPage.ratesAndFeesSection.title')}
        </Typography>
        <div className={styles.cards}>
          <div className={styles.card}>
            <div className={styles.iconWrapper}>
              <TransactionIcon />
            </div>
            <div className={styles.cardText}>
              <Typography variant="h4">{t('indexPage.ratesAndFeesSection.card1.title')}</Typography>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.iconWrapper}>
              <ProfileIcon />
            </div>
            <div className={styles.cardText}>
              <Typography variant="h4">{t('indexPage.ratesAndFeesSection.card2.title')}</Typography>
            </div>
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
