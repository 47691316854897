export const locales: { [locale: string]: string } = {
  en: 'en',
  ie: 'ie',
  lt: 'lt',
  lv: 'lv',
  ro: 'ro',
  pl: 'pl',
};

export const mapCountyCodeToLocale = (countryCode: string) => {
  return countryCode.toLowerCase();
};
