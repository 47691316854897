import styles from './SmallCardsSection.module.scss';
import Image9 from '../../../images/indexPage.image9.png';
import { ReactComponent as Image10 } from '../../../images/Coin.svg';
import Image11 from '../../../images/indexPage.image11.png';
import { Typography } from '../../common/Typography/Typography';
import { useTranslation } from 'react-i18next';

export const SmallCardsSection = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.column}>
        <div className={styles.imgContainer}>
          <img src={Image9} className={styles.img} />
        </div>
        <Typography variant="pMediumMedium">{t('indexPage.section9.card1.title')}</Typography>
        <Typography variant="pSmallRegular" color="var(--text2)" align="center">
          {t('indexPage.section9.card1.subtitle')}
        </Typography>
      </div>
      <div className={styles.column}>
        <div className={styles.imgContainer}>
          <div className={styles.round}>
            <Image10 />
          </div>
        </div>
        <Typography variant="pMediumMedium">{t('indexPage.section9.card2.title')}</Typography>
        <Typography variant="pSmallRegular" color="var(--text2)" align="center">
          {t('indexPage.section9.card2.subtitle')}
        </Typography>
      </div>
      <div className={styles.column}>
        <div className={styles.imgContainer}>
          <img src={Image11} className={styles.img} />
        </div>
        <Typography variant="pMediumMedium">{t('indexPage.section9.card3.title')}</Typography>
        <Typography variant="pSmallRegular" color="var(--text2)" align="center">
          {t('indexPage.section9.card3.subtitle')}
        </Typography>
      </div>
    </div>
  );
};
