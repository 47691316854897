import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './Section4.module.scss';
import BrightyWord from '../../../images/TonPage/BrightyWord.svg';
import { Typography } from '../../common/Typography/Typography';
import BrightyLogo from '../../../images/BrightyLetter.svg';
import CartIcon from '../../../images/TonPage/CartIcon.svg';
import SignUpIcon from '../../../images/TonPage/SignUpIcon.svg';
import Coins from '../../../images/TonPage/Coins.svg';

const columns = [
  {
    icon: SignUpIcon,
    title: 'Sign Up:',
    subtitle:
      'Get started by creating an account through our user-friendly mobile app. Verification is quick and easy, so you can select and start using your card in no time.',
  },
  {
    icon: Coins,
    title: 'Load Funds:',
    subtitle:
      'Fund your card by depositing cryptocurrencies from your digital wallet. Brighty App supports a wide range of popular cryptocurrencies, including TON, Bitcoin, Ethereum, USDT, and more. You can link any of your accounts to the TON card.',
  },
  {
    icon: CartIcon,
    title: 'Shop Anywhere:',
    subtitle:
      "Once your card is loaded, you're ready to start spending! Use your TON Digital Crypto Card at millions of merchants worldwide that accept Visa payments, both onlineand in-store.",
  },
];

export const Section4 = () => {
  return (
    <WidthWrapper cropped className={styles.widthWrapper}>
      <div className={styles.card}>
        <div className={styles.top}>
          <img src={BrightyWord} alt="BrightyWord" />
        </div>
        <div className={styles.content}>
          <Typography variant="h1" align="center" alignMobile="center">
            How to Get the TON
            <br />
            <img src={BrightyLogo} className={styles.logo} />
            &nbsp; Brighty Card:
          </Typography>
          <div className={styles.columns}>
            {columns.map((column) => (
              <div className={styles.column} key={column.title}>
                <div className={styles.iconWrapper}>
                  <img src={column.icon} alt="" className={styles.icon} />
                </div>
                <div className={styles.column__text}>
                  <Typography
                    className={styles.column__title}
                    variant="pLargeRegular"
                    align="center"
                    alignMobile="center"
                  >
                    {column.title}
                  </Typography>
                  <Typography
                    className={styles.column__subtitle}
                    color="var(--text2)"
                    variant="pSmallRegular"
                    align="center"
                    alignMobile="center"
                  >
                    {column.subtitle}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
