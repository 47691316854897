/* eslint-disable import/no-default-export */

import { withTranslation } from 'react-i18next';
import { HeaderSection } from '../components/PayPage/HeaderSection/HeaderSection';

const PayPage = () => {
  return (
    <>
      <HeaderSection />
    </>
  );
};

export default withTranslation()(PayPage);
