import { HeaderSection } from '../components/ProofOfReservesPage/HeaderSection/HeaderSection';
import { InfoSection } from '../components/ProofOfReservesPage/InfoSection/InfoSection';

export const ProofOfReservesPage = () => {
  return (
    <>
      <HeaderSection />
      <InfoSection />
    </>
  );
};
