import { ReactNode, FC } from 'react';
import { Footer } from '../../components/common/Footer/Footer';
import { Header } from '../../components/common/Header/Header';
import styles from './MainLayout.module.scss';

interface IProps {
  children: ReactNode;
  landing?: boolean;
}

export const MainLayout: FC<IProps> = ({ children }) => {
  return (
    <div className={styles.page}>
      <Header />
      <main className={styles.main}>{children}</main>
      {/* <SocialSection /> */}
      <Footer />
    </div>
  );
};
