import { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Header } from '../components/common/Header/Header';

const СookiePolicyPage = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    const resize = () => {
      if (!iframe) {
        return;
      }
      iframe.style.height = iframe?.contentDocument?.body.scrollHeight + 'px';
    };
    if (iframe) {
      iframe.addEventListener('load', resize);
    }
    return () => {
      if (iframe) {
        iframe.removeEventListener('load', resize);
      }
    };
  }, [iframeRef]);

  return (
    <>
      <Header />
      <iframe ref={iframeRef} src="cookie-policy.html" style={{ border: 'none', width: '100%' }} />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default withTranslation()(СookiePolicyPage);
