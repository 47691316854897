import { useEffect } from 'react';
import i18n from '../i18n/config';
import { locales, mapCountyCodeToLocale } from '../const/locales';
import { useIpCountry } from '../api/invite';

export const LanguageHOC = () => {
  const ipCountry = useIpCountry();

  useEffect(() => {
    const prevLang = localStorage.getItem('lang');
    if (prevLang) {
      if (prevLang && locales[prevLang]) {
        i18n.changeLanguage(prevLang);
      }
    } else {
      const countryCode = ipCountry.data?.country;
      if (countryCode && locales[mapCountyCodeToLocale(countryCode)]) {
        i18n.changeLanguage(locales[mapCountyCodeToLocale(countryCode)]);
      }
    }
  }, [ipCountry.data?.country]);

  return null;
};
