/* eslint-disable import/no-default-export */

import { useTranslation } from 'react-i18next';
import { RenderLayout } from '../layouts/RenderLayout/RenderLayout';
import ApplePay from '../images/ApplePay.png';

import { ReactComponent as AppleWallet } from '../images/AppleWallet.svg';
import { ReactComponent as ContactlessPayment } from '../images/ContactlessPayment.svg';
import { ReactComponent as Watch } from '../images/Watch.svg';

const ApplePayPage = () => {
  const { t } = useTranslation();

  return (
    <RenderLayout
      top
      components={[
        {
          type: 'CENTERSECTION',
          title: t('applePayPage.header.title'),
          subtitle: t('applePayPage.header.subtitle'),
          imageSrc: ApplePay,
          fullSize: true,
        },
        {
          type: 'CARDSSECTION',
          title: t('applePayPage.section1.title'),
          cards: [
            {
              title: t('applePayPage.section1.card1.title'),
              icon: <AppleWallet />,
            },
            {
              title: t('applePayPage.section1.card2.title'),
              icon: <Watch />,
            },
            {
              title: t('applePayPage.section1.card3.title'),
              subtitle: t('applePayPage.section1.card3.subtitle'),
              icon: <ContactlessPayment />,
            },
          ],
        },
      ]}
    />
  );
};

export default ApplePayPage;
