import { Link, NavLink } from 'react-router-dom';
import { Typography } from '../Typography/Typography';
import styles from './Footer.module.scss';
import InstagramIcon from '../../../images/Instagram.svg';
import TwitterIcon from '../../../images/Twitter.svg';
import LinkedinIcon from '../../../images/Linkedin.svg';
import TelegramIcon from '../../../images/Telegram.svg';
import SwitzerlandIcon from '../../../images/Switzerland.svg';
import classNames from 'classnames';

const links = [
  {
    heading: 'Company',
    links: [
      {
        text: 'About us',
        link: '/company',
      },
      {
        text: 'Business',
        link: '/business',
      },
      {
        text: 'AI investments',
        link: '/invest',
      },
      {
        text: 'Affiliate',
        link: '/affiliate',
      },
      {
        text: 'Team',
        link: '/team',
      },
      {
        text: 'Careers',
        link: '/careers',
        reload: false,
      },
      {
        text: 'Legal information',
        link: '/termsAndPolicies',
      },
      {
        text: 'Proof of Reserves',
        link: '/proof-of-reserves',
      },
      {
        text: 'Imprint',
        link: '/imprint',
      },
    ],
  },

  [
    {
      heading: 'Resources',
      links: [
        {
          text: 'Customer help',
          link: 'https://help.brighty.app/en/',
        },
      ],
    },
    {
      heading: 'Payments',
      links: [
        {
          text: 'Pay with Apple pay',
          link: '/applePay',
        },
        {
          text: 'Pay with Google pay',
          link: '/googlePay',
        },
      ],
    },
  ],
];

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <picture>
              <source srcSet="/brighty-logo.avif" type="image/avif" />
              <img src="/brighty-logo.png" alt="Brighty logo" height="48" width="48" />
            </picture>
          </Link>
        </div>
        <Typography variant="pSmallRegular">
          Razz Finanzz AG, registered address: Mühlegasse 18, 6340 Baar, Switzerland. Brighty
          Digital UAB, registered address: Konstitucijos pr. 21, Vilnius 09306, Lithuania. Brighty
          Digital UAB holds a crypto exchange license in the Republic of Lithuania.
        </Typography>
        <div className={styles.columns}>
          {links.map((c) => {
            if (!Array.isArray(c)) {
              return (
                <div className={styles.column} key={c.heading}>
                  <Typography variant="pSmallMedium">{c.heading}</Typography>
                  {c.links.map((l) => {
                    if (l.reload) {
                      return (
                        <a
                          key={l.text}
                          href={l.link}
                          className={classNames(styles.link, {
                            [styles.link__disabled]: !l.link,
                          })}
                        >
                          <Typography variant="pSmallRegular">{l.text}</Typography>
                        </a>
                      );
                    } else {
                      return (
                        <NavLink
                          key={l.text}
                          to={l.link}
                          className={classNames(styles.link, {
                            [styles.link__disabled]: !l.link,
                          })}
                        >
                          <Typography variant="pSmallRegular">{l.text}</Typography>
                        </NavLink>
                      );
                    }
                  })}
                </div>
              );
            } else {
              return (
                <div className={styles.bigColumn} key={c[0].heading}>
                  {c.map((col) => (
                    <div className={styles.column} key={col.heading}>
                      <Typography variant="pSmallMedium">{col.heading}</Typography>
                      {col.links.map((l) => {
                        return (
                          <NavLink
                            key={l.link}
                            to={l.link}
                            className={classNames(styles.link, {
                              [styles.link__disabled]: !l.link,
                            })}
                          >
                            <Typography variant="pSmallRegular">{l.text}</Typography>
                          </NavLink>
                        );
                      })}
                    </div>
                  ))}
                </div>
              );
            }
          })}
          <div className={styles.bigColumn}>
            <div className={styles.column}>
              <Typography variant="pSmallMedium">Business</Typography>
              <NavLink to={'https://apidocs.brighty.app/'} className={styles.link} target="_blank">
                <Typography variant="pSmallRegular">API for businesses</Typography>
              </NavLink>
            </div>
            <div className={styles.column}>
              <Typography variant="pSmallMedium">Follow</Typography>
              <div className={styles.socials}>
                <NavLink
                  to="https://www.linkedin.com/company/brightyapp"
                  className={styles.socialLink}
                  target="_blank"
                >
                  <img src={LinkedinIcon} alt="Linkedin" />
                </NavLink>
                <NavLink
                  to="https://twitter.com/brightyapp"
                  className={styles.socialLink}
                  target="_blank"
                >
                  <img src={TwitterIcon} alt="Twitter" />
                </NavLink>
                <NavLink
                  to="https://instagram.com/brighty.app"
                  className={styles.socialLink}
                  target="_blank"
                >
                  <img src={InstagramIcon} alt="Instagram" />
                </NavLink>
                <NavLink to="https://t.me/brightyapp" className={styles.socialLink} target="_blank">
                  <img src={TelegramIcon} alt="Telegram" />
                </NavLink>
                {/* <NavLink to="https://discord.gg/UKgbqYvna5" className={styles.socialLink}>
                <img src={DiscordIcon} alt="Discord" />
              </NavLink> */}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.copyright}>
          <Typography variant="pSmallRegular">
            ©2022-2024. Razz Finanzz AG. Brighty Digital UAB.
          </Typography>
          <Typography variant="pSmallRegular" align="center" className={styles.established}>
            Established in Switzerland
            <img src={SwitzerlandIcon} alt="Switzerland" />
          </Typography>
        </div>
      </div>
    </footer>
  );
};
