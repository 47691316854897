import { Spacer } from '../../common/Spacer/Spacer';
import { Typography } from '../../common/Typography/Typography';
import styles from './MainSection.module.scss';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import { Link } from '../../common/Link/Link';

const TCLinks = [
  {
    title: 'General',
    link: '/fiat-terms-and-conditions',
  },
  {
    title: 'Cryptocurrency',
    link: '/cryptocurrency-offering-terms-and-conditions',
  },
  {
    title: 'Earning vaults',
    link: '/earning-vault-terms-and-conditions',
  },
  {
    title: 'Payment card',
    link: '/payment-card-terms-and-conditions',
  },
  {
    title: 'Subscriptions',
    link: '/subscription-terms-and-conditions',
  },
  {
    title: 'Investment',
    link: '/investment-offering-terms-and-conditions',
  },
  {
    title: 'Referral Program',
    link: '/referral-terms-and-conditions',
  },
];

const PPLinks = [
  {
    title: 'Invest AI risk',
    link: '/invest-risk-notice',
  },
  {
    title: 'Privacy',
    link: '/privacy-policy',
  },
  {
    title: 'Cookie',
    link: '/cookie-policy',
  },
];

export const MainSection = () => {
  return (
    <WidthWrapper className={styles.root}>
      <div className={styles.column}>
        <div>
          <Typography variant="h1">Terms and Policies</Typography>
          <Spacer h={16} />
          <Typography variant="pLargeRegular">Region: EU</Typography>
        </div>
        <div className={styles.wrapper}>
          <Typography variant="h4" className={styles.title}>
            Terms and Conditions
          </Typography>
          {TCLinks.map((c) => (
            <Link key={c.title} href={c.link} title={c.title} black />
          ))}
        </div>
        <div className={styles.wrapper}>
          <Typography variant="h4" className={styles.title}>
            Privacy and Risk Protection
          </Typography>
          {PPLinks.map((c) => (
            <Link key={c.title} href={c.link} title={c.title} black />
          ))}
        </div>
      </div>
    </WidthWrapper>
  );
};
