import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './HeaderSection.module.scss';

export const HeaderSection = () => {
  return (
    <WidthWrapper className={styles.root}>
      <Typography variant="h1" className={styles.title}>
        Imprint
      </Typography>
      <Typography variant="pLargeRegular" className={styles.text}>
        This website is jointly provided by Razz Finanzz AG, Brighty Digital UAB and Brighty App OU.
      </Typography>
    </WidthWrapper>
  );
};
