export const hideCookieBannerURLs = [
  '/privacy-policy',
  '/earning-vault-terms-and-conditions',
  '/terms-and-conditions',
  '/cookie-policy',
  '/cryptocurrency-offering-terms-and-conditions',
  '/fiat-terms-and-conditions',
  '/invest-risk-notice',
  '/investment-offering-terms-and-conditions',
  '/payment-card-terms-and-conditions',
  '/referral-terms-and-conditions',
  '/subscription-terms-and-conditions',
];
