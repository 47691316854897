import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './Section2.module.scss';
import Stack from '../../../images/AIInvestPage/Stack.png';
import Cactus from '../../../images/AIInvestPage/Cactus.png';
import Tool from '../../../images/AIInvestPage/Tool.png';

import classNames from 'classnames';

export const Section2 = () => {
  return (
    <WidthWrapper>
      <div className={styles.root}>
        <div className={styles.top}>
          <div className={styles.title}>
            <Typography variant="h1" className={styles.gradient} alignMobile="center">
              AI Investment
            </Typography>
            <Typography variant="h1" alignMobile="center">
              Management
            </Typography>
          </div>
          <Typography variant="h4" className={styles.subtitle} alignMobile="center">
            Brighty’s AI investment management platform offers:
          </Typography>
        </div>
        <div className={styles.cards}>
          <div className={styles.cards__topRow}>
            <div className={styles.card}>
              <img src={Stack} alt="stack" className={styles.card__image} />
              <div className={styles.card__text}>
                <Typography className={styles.card__title} alignMobile="center">
                  Data-driven strategies
                </Typography>
                <Typography className={styles.card__subtitle} alignMobile="center">
                  You can invest in with one click.
                </Typography>
              </div>
            </div>
            <div className={styles.card}>
              <img src={Cactus} alt="cactus" className={styles.card__image} />
              <div className={styles.card__text}>
                <Typography className={styles.card__title} alignMobile="center">
                  Diverse portfolios
                </Typography>
                <Typography className={styles.card__subtitle} alignMobile="center">
                  Diverse portfolios based on sectors like Momentum, Industrial, Nasdaq, S&P 500,
                  Energy, Real Estate, and Dividends.
                </Typography>
              </div>
            </div>
          </div>
          <div className={classNames(styles.card, styles.card__big)}>
            <img src={Tool} alt="tool" className={styles.card__big__image} />
            <div className={styles.card__text}>
              <Typography className={styles.card__title} alignMobile="center">
                Continuous adjustments
              </Typography>
              <Typography className={styles.card__subtitle} alignMobile="center">
                Continuous monitoring and adjustments based on market trends, news, and behaviors to
                optimize returns.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
