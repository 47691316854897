/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-default-export */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      no_filename: require('./locales/en/no_filename.json'),
    },
    ro: {
      no_filename: require('./locales/ro_RO/no_filename.json'),
    },
    pl: {
      no_filename: require('./locales/pl_PL/no_filename.json'),
    },
    lv: {
      no_filename: require('./locales/lv_LV/no_filename.json'),
    },
    lt: {
      no_filename: require('./locales/lt_LT/no_filename.json'),
    },
    ie: {
      no_filename: require('./locales/ga_IE/no_filename.json'),
    },
  },
  ns: ['no_filename'],
  defaultNS: 'no_filename',
});

i18n.languages = ['en', 'ro', 'pl', 'lv', 'lt', 'ie'];

export default i18n;
