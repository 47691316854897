import { FC, ReactNode } from 'react';
import styles from './IconCaption.module.scss';
import { Typography } from '../Typography/Typography';

interface IProps {
  icon: ReactNode;
  color: string;
  text: string;
}

export const IconCaption: FC<IProps> = ({ icon, color, text }) => {
  return (
    <div className={styles.root}>
      <div className={styles.icon} style={{ background: color }}>
        {icon}
      </div>
      <Typography variant="pSmallRegular" color={color}>
        {text}
      </Typography>
    </div>
  );
};
