/* eslint-disable import/no-default-export */
import { withTranslation } from 'react-i18next';
import { MainSection } from '../components/TermsAndPolicies/MainSection/MainSection';
import { Header } from '../components/common/Header/Header';

const TermsAndPoliciesPage = () => {
  return (
    <>
      <Header />
      <MainSection />
    </>
  );
};

export default withTranslation()(TermsAndPoliciesPage);
