import { FC } from 'react';
import styles from './Link.module.scss';
import { Typography } from '../Typography/Typography';
import { NavLink } from 'react-router-dom';

interface IProps {
  href: string;
  title: string;
  black?: boolean;
}

export const Link: FC<IProps> = ({ href, title, black }) => {
  return (
    <NavLink to={href} className={styles.root}>
      <Typography variant="pMediumMedium" color={black ? 'var(--text0)' : 'var(--secondary)'}>
        {title}
      </Typography>
      {/* <ArrowRightIcon /> */}
    </NavLink>
  );
};
