import { useTranslation } from 'react-i18next';

import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './FraudSection.module.scss';
import { Typography } from '../../common/Typography/Typography';

export const FraudSection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper grey>
      <div className={styles.root}>
        <Typography variant="h4" color="var(--text2)">
          {t('careersPage.section5.text1')}
        </Typography>
        <Typography variant="pSmallRegular" color="var(--text2)">
          {t('careersPage.section5.text2')}
        </Typography>
        <Typography variant="pSmallRegular" color="var(--text2)">
          {t('careersPage.section5.text3')}
        </Typography>
        <Typography variant="pSmallRegular" color="var(--text2)">
          <ul>
            <li>{t('careersPage.section5.text4')}</li>
            <li>{t('careersPage.section5.text5')}</li>
            <li>{t('careersPage.section5.text6')}</li>
            <li>{t('careersPage.section5.text7')}</li>
            <li>{t('careersPage.section5.text8')}</li>
            <li>{t('careersPage.section5.text9')}</li>
          </ul>
        </Typography>
        <Typography variant="pSmallRegular" color="var(--text2)">
          {t('careersPage.section5.text10')}&nbsp;
          <a href="mailto: hr@brighty.app" className={styles.link}>
            hr@brighty.app
          </a>
          &nbsp;{t('careersPage.section5.text11')}
        </Typography>
        {/* <Typography variant="pSmallRegular" color="var(--text2)">
          {t('careersPage.section5.text12')}
          &nbsp;
          <a href="/" className={styles.link}>
            {t('careersPage.section5.link1')}
          </a>
        </Typography> */}
      </div>
    </WidthWrapper>
  );
};
