import { useTranslation } from 'react-i18next';

import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './HeaderSection.module.scss';

export const HeaderSection = () => {
  const { t } = useTranslation();

  return (
    <WidthWrapper className={styles.root}>
      <Typography variant="h1" className={styles.title}>
        {t('proofOfReservesPage.header.title')}
      </Typography>
      <Typography variant="pLargeRegular" className={styles.text}>
        {t('proofOfReservesPage.header.subtitle')}
      </Typography>
    </WidthWrapper>
  );
};
