import { useLightMutation, useLightQuery } from './useLightQuery';

export function useIpCountry() {
  return useLightQuery<{ country: string }>({
    url: 'https://business.brighty.codes/geoinfo',
    method: 'GET',
    enabled: true,
    withHeaders: false,
  });
}

export function usePromoRedeem() {
  return useLightMutation({
    url: `/promo/redeem`,
    method: 'POST',
  });
}
