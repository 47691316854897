import { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import styles from './Typography.module.scss';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface IProps {
  className?: string;
  children: ReactNode;
  align?: 'center' | 'left' | 'right';
  alignMobile?: 'center' | 'left' | 'right';
  alignTablet?: 'center' | 'left' | 'right';
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'pLargeSemibold'
    | 'pLargeMedium'
    | 'pLargeRegular'
    | 'pMediumMedium'
    | 'pMediumSemibold'
    | 'pMediumRegular'
    | 'pSmallMedium'
    | 'pSmallSemibold'
    | 'pSmallRegular'
    | 'captionMedium'
    | 'captionSemibold'
    | 'captionRegular'
    | 'otherMedium';
  color?: string;
  display?: 'inline-flex' | 'block';
}

export const Typography: FC<IProps> = ({
  className,
  children,
  variant = 'pMediumMedium',
  align = 'left',
  alignMobile = 'left',
  alignTablet,
  color,
  display = 'block',
}) => {
  const nestedTablet = useMemo(() => (alignTablet ? alignTablet : align), [alignTablet, align]);

  const isMobile = useMediaQuery('(max-width: 577px)');
  const isTablet = useMediaQuery('(max-width: 1000px)');
  const finalAlign = useMemo(
    () => (isMobile ? alignMobile : isTablet ? nestedTablet : align),
    [isMobile, isTablet, align, alignMobile, nestedTablet],
  );

  if (variant === 'h1') {
    return (
      <h1
        className={classNames(styles.text, styles[variant], className)}
        style={{ textAlign: finalAlign, color: color }}
      >
        {children}
      </h1>
    );
  }

  return (
    <p
      className={classNames(styles.text, styles[variant], className)}
      style={{ textAlign: finalAlign, color: color, display: display }}
    >
      {children}
    </p>
  );
};
