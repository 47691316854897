import { useTranslation } from 'react-i18next';

import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './PartnersSection.module.scss';
import { ReactComponent as FireblocksLogo } from '../../../images/fireblocks.svg';
import { ReactComponent as SumsubLogo } from '../../../images/sumsub.svg';
import { ReactComponent as WallesterLogo } from '../../../images/Wallester.svg';
import { ReactComponent as MonerchyLogo } from '../../../images/monerchy.svg';
import { ReactComponent as PlaidLogo } from '../../../images/plaid.svg';
import { ReactComponent as NasdaqLogo } from '../../../images/nasdaq.svg';
import { ReactComponent as BenzingaLogo } from '../../../images/benzinga.svg';
import { ReactComponent as EllipticLogo } from '../../../images/Elliptic.svg';
import { ReactComponent as CoinEditionLogo } from '../../../images/coinedition.svg';
import { ReactComponent as YahooLogo } from '../../../images/yahoo.svg';
import { ReactComponent as CryptonewsLogo } from '../../../images/cryptonews.svg';
import { ReactComponent as BitconinMagazineLogo } from '../../../images/bitcoinmagazine.svg';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

export const PartnersSection = () => {
  const isMobile = useMediaQuery('(max-width: 577px)');
  const { t } = useTranslation();

  return (
    <>
      <WidthWrapper>
        <div className={styles.root}>
          <div className={styles.title}>
            <Typography variant="h1" align={isMobile ? 'left' : 'center'}>
              {t('companyPage.section3.title')}
            </Typography>
          </div>
          <div className={styles.subtitle}>
            <Typography variant="pLargeRegular" align={isMobile ? 'left' : 'center'}>
              {t('companyPage.section3.subtitle')}
            </Typography>
          </div>
          <div className={styles.cardsWrapper}>
            <div className={styles.cards} id="cards">
              <a href="https://sumsub.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <SumsubLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.sumsub')}
                  </Typography>
                </div>
              </a>
              <a href="https://www.fireblocks.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <FireblocksLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.fireblocks')}
                  </Typography>
                </div>
              </a>
              <a href="https://www.chainalysis.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <EllipticLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.chainalysis')}
                  </Typography>
                </div>
              </a>
              <a href="https://monerchy.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <MonerchyLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.monerchy')}
                  </Typography>
                </div>
              </a>
              <a href="https://plaid.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <PlaidLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.plaid')}
                  </Typography>
                </div>
              </a>
              <a href="https://wallester.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <WallesterLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.wallester')}
                  </Typography>
                </div>
              </a>
            </div>
            <div className={styles.cards}>
              <a href="https://sumsub.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <SumsubLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.sumsub')}
                  </Typography>
                </div>
              </a>
              <a href="https://www.fireblocks.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <FireblocksLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.fireblocks')}
                  </Typography>
                </div>
              </a>
              <a href="https://www.chainalysis.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <EllipticLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.chainalysis')}
                  </Typography>
                </div>
              </a>
              <a href="https://monerchy.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <MonerchyLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.monerchy')}
                  </Typography>
                </div>
              </a>
              <a href="https://plaid.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <PlaidLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.plaid')}
                  </Typography>
                </div>
              </a>
              <a href="https://wallester.com/" target="_blank" rel="noreferrer">
                <div className={styles.card}>
                  <WallesterLogo />
                  <Typography variant="h4" className={styles.text}>
                    {t('companyPage.section3.cards.wallester')}
                  </Typography>
                </div>
              </a>
            </div>
          </div>
          <div className={styles.mobileCards}>
            <a href="https://sumsub.com/" target="_blank" rel="noreferrer" className={styles.card}>
              <SumsubLogo />
              <Typography variant="h4" className={styles.text}>
                {t('companyPage.section3.cards.sumsub')}
              </Typography>
            </a>
            <a
              href="https://www.fireblocks.com/"
              target="_blank"
              rel="noreferrer"
              className={styles.card}
            >
              <FireblocksLogo />
              <Typography variant="h4" className={styles.text}>
                {t('companyPage.section3.cards.fireblocks')}
              </Typography>
            </a>
            <a
              href="https://www.chainalysis.com/"
              target="_blank"
              rel="noreferrer"
              className={styles.card}
            >
              <EllipticLogo />
              <Typography variant="h4" className={styles.text}>
                {t('companyPage.section3.cards.chainalysis')}
              </Typography>
            </a>
            <a
              href="https://monerchy.com/"
              target="_blank"
              rel="noreferrer"
              className={styles.card}
            >
              <MonerchyLogo />
              <Typography variant="h4" className={styles.text}>
                {t('companyPage.section3.cards.monerchy')}
              </Typography>
            </a>
            <a href="https://plaid.com/" target="_blank" rel="noreferrer" className={styles.card}>
              <PlaidLogo />
              <Typography variant="h4" className={styles.text}>
                {t('companyPage.section3.cards.plaid')}
              </Typography>
            </a>
            <a
              href="https://wallester.com/"
              target="_blank"
              rel="noreferrer"
              className={styles.card}
            >
              <WallesterLogo />
              <Typography variant="h4" className={styles.text}>
                {t('companyPage.section3.cards.wallester')}
              </Typography>
            </a>
          </div>
        </div>
      </WidthWrapper>
      <Typography variant="h4" align="center" alignMobile="center">
        {t('Mentioned in news')}
      </Typography>
      <div className={styles.partners}>
        <NasdaqLogo />
        <BenzingaLogo />
        <CoinEditionLogo />
        <YahooLogo />
        <CryptonewsLogo />
        <BitconinMagazineLogo />
      </div>
    </>
  );
};
