import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './InfoSection.module.scss';

export const InfoSection = () => {
  return (
    <WidthWrapper>
      <div className={styles.root}>
        <div className={styles.card}>
          <Typography variant="h4">Razz Finanzz AG</Typography>
          <div className={styles.block}>
            <Typography variant="pMediumRegular">
              Mühlegasse 18, Baar, Zug, Switzerland, 6340
            </Typography>
            <Typography variant="pSmallRegular" color="var(--text2)">
              Address
            </Typography>
          </div>
          <div className={styles.block}>
            <Typography variant="pMediumRegular">CHE-274.335.581</Typography>
            <Typography variant="pSmallRegular" color="var(--text2)">
              Register number
            </Typography>
          </div>
          <div className={styles.block}>
            <Typography variant="pMediumRegular">support@brighty.app</Typography>
            <Typography variant="pSmallRegular" color="var(--text2)">
              E-mail
            </Typography>
          </div>
        </div>
        <div className={styles.card}>
          <Typography variant="h4">Brighty Digital UAB</Typography>
          <div className={styles.block}>
            <Typography variant="pMediumRegular">Vilnius, Konstitucijos pr. 21</Typography>
            <Typography variant="pSmallRegular" color="var(--text2)">
              Address
            </Typography>
          </div>
          <div className={styles.block}>
            <Typography variant="pMediumRegular">306099818</Typography>
            <Typography variant="pSmallRegular" color="var(--text2)">
              Register number
            </Typography>
          </div>
          <div className={styles.block}>
            <Typography variant="pMediumRegular">support@brighty.app</Typography>
            <Typography variant="pSmallRegular" color="var(--text2)">
              E-mail
            </Typography>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.block}>
            <Typography variant="pMediumRegular">996335</Typography>
            <Typography variant="pSmallRegular" color="var(--text2)">
              Firm reference number
            </Typography>
          </div>
          <div className={styles.block}>
            <Typography variant="pMediumRegular">14488206</Typography>
            <Typography variant="pSmallRegular" color="var(--text2)">
              Register company number
            </Typography>
          </div>
          <div className={styles.block}>
            <Typography variant="pMediumRegular">support@brighty.app</Typography>
            <Typography variant="pSmallRegular" color="var(--text2)">
              E-mail
            </Typography>
          </div>
        </div>
        <div className={styles.card}>
          <Typography variant="h4">Supervisory authority</Typography>
          <div className={styles.block}>
            <Typography variant="pMediumRegular">
              Brighty Digital UAB supervised by the Financial Crime Investigation Service under The
              Ministry of the Interior of the Republic of Lithuania https://fntt.lt/en/.
            </Typography>
            <br />
            <Typography variant="pMediumRegular">
              Razz Finanzz is a member of VQF SRO (ID 101076). VQF is officially recognised and
              supervised by The Swiss Financial Market Supervisory Authority (FINMA).
            </Typography>
          </div>
        </div>
        <div className={styles.card}>
          <Typography variant="h4">Dispute resolution</Typography>
          <div className={styles.block}>
            <Typography variant="pMediumRegular">
              Disputes related to providing services shall be resolved by the relevant court of
              Switzerland.{' '}
            </Typography>
          </div>
        </div>
      </div>
    </WidthWrapper>
  );
};
