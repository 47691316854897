/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Typography } from '../../Typography/Typography';
import styles from './BusinessModal.module.scss';
import { send } from '@emailjs/browser';
import { useBool } from '../../../../hooks/useBool';
import { CountrySelect } from '../../CountrySelect/CountrySelect';
import { Button } from '../../Button/Button';
import { config } from '../../../../config/config';
import Success from '../../../../images/success.png';
import { Input } from '../../Input/Input';
import { PhoneInput } from '../../PhoneInput/PhoneInput';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const BusinessModal: FC<IProps> = ({ open, onClose }) => {
  const isValid = useBool();
  const [formValue, setFormValue] = useState<{
    email: string;
    name: string;
    description: string;
    country: string;
    phone: string;
    companyName: string;
  }>({
    email: '',
    name: '',
    description: '',
    country: '',
    phone: '',
    companyName: '',
  });
  const success = useBool();
  const termsChecked = useBool();
  const loading = useBool();

  const handleChange = useCallback((fieldName: string, event: ChangeEvent<HTMLInputElement>) => {
    setFormValue((prev) => ({ ...prev, [fieldName]: event.target.value }));
  }, []);

  const handleChangeCountry = useCallback((value: string) => {
    setFormValue((prev) => ({ ...prev, country: value }));
  }, []);

  const handlePhoneChange = useCallback((value: string) => {
    setFormValue((prev) => ({ ...prev, phone: value }));
  }, []);

  useEffect(() => {
    if (!formValue.country || !formValue.email || !formValue.name) {
      isValid.onFalse();
    } else {
      isValid.onTrue();
    }
  }, [formValue, isValid]);

  const handleClose = () => {
    termsChecked.onFalse();
    setFormValue({
      email: '',
      name: '',
      description: '',
      country: '',
      phone: '',
      companyName: '',
    });
    isValid.onFalse();
    loading.onFalse();
    success.onFalse();
    onClose();
  };

  const closePopupOnOverlay = useCallback(
    (evt: any) => {
      if (evt.target === evt.currentTarget) {
        handleClose();
      }
    },
    [onClose],
  );

  const sendEmail = async (e: any) => {
    e.preventDefault();
    if (!formValue.country || !formValue.email || !formValue.name) {
      return;
    }
    loading.onTrue();
    await send(
      config.MAIL_SERVICE_ID,
      config.MAIL_TEMPLATE_ID,
      {
        email: formValue.email,
        country: formValue.country,
        name: formValue.name,
        description: formValue.description,
        phone: formValue.phone,
        companyName: formValue.companyName,
      },
      config.MAIL_PUBLIC_KEY,
    ).then(() => success.onTrue());
  };

  if (!open) {
    return null;
  }

  if (success.value) {
    return (
      <div className={styles.wrapper} onClick={closePopupOnOverlay}>
        <div className={styles.modal}>
          <div className={styles.columnCenter}>
            <img src={Success} alt="success" />
            <Typography variant="h4">Thank you!</Typography>
            <Typography variant="pMediumRegular" color="var(--text2)">
              We'll contact you shortly
            </Typography>
            <Button title="Close" theme="secondary" onClick={handleClose} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper} onClick={closePopupOnOverlay}>
      <div className={styles.modal}>
        <Typography variant="h4">Contact us</Typography>
        <form className={styles.form} onSubmit={sendEmail}>
          <div className={styles.inputs}>
            <Input
              id="email"
              name="email"
              type="email"
              label="Email"
              placeholder="email"
              value={formValue.email}
              onChange={handleChange}
              autoComplete="off"
            />
            <PhoneInput label="Phone" onChange={handlePhoneChange} />
            <CountrySelect
              value={formValue.country}
              label="Country"
              onChange={handleChangeCountry}
            />
            <Input
              id="name"
              name="name"
              label="Your name"
              placeholder="Name"
              value={formValue.name}
              onChange={handleChange}
              autoComplete="off"
            />
            <Input
              id="companyName"
              name="companyName"
              label="Company name"
              placeholder="Company name"
              value={formValue.companyName}
              onChange={handleChange}
              autoComplete="off"
            />
            <Input
              id="description"
              name="description"
              label="Description (optional)"
              placeholder="Description (optional)"
              value={formValue.description}
              onChange={handleChange}
              autoComplete="off"
            />
          </div>
          <div className={styles.buttons}>
            <Button theme="secondary" onClick={handleClose} title="Cancel" />
            <Button
              theme="primary"
              disabled={loading.value || !isValid.value}
              title="Send"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
