import { useThemeSwitcher } from 'react-css-theme-switcher';
import { Typography } from '../../common/Typography/Typography';
import AppStore from '../../../images/AppStore.svg';
import GooglePlay from '../../../images/GooglePlay.svg';
import AppStoreDark from '../../../images/AppStore-dark.svg';
import GooglePlayDark from '../../../images/GooglePlay-dark.svg';
import styles from './HeaderSection.module.scss';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import Duck from '../../../images/Duck.png';
import { Image } from '../../common/Image/Image';

export const HeaderSection = () => {
  const { currentTheme } = useThemeSwitcher();

  return (
    <WidthWrapper center cropped>
      <div className={styles.root}>
        <div className={styles.text}>
          <Typography variant="h1" className={styles.title} align="center">
            Oops!
          </Typography>
          <Typography variant="pLargeRegular" className={styles.subtitle} align="center">
            You still don’t have Brighty app. <br /> Download Brighty app and pay in crypto
          </Typography>
        </div>
        <div className={styles.buttons}>
          <a
            href="https://brightyapp.go.link?adj_t=7d2bnfa&adj_redirect=https%3A%2F%2Fapps.apple.com%2Fgb%2Fapp%2Fbrighty-pay-save-and-earn%2Fid1635701696"
            target="_blank"
            rel="noreferrer"
          >
            <img src={currentTheme === 'light' ? AppStore : AppStoreDark} />
          </a>
          <a
            href="https://brightyapp.go.link?adj_t=qr3gjca&adj_redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.brighty.app"
            target="_blank"
            rel="noreferrer"
          >
            <img src={currentTheme === 'light' ? GooglePlay : GooglePlayDark} />
          </a>
        </div>
        <Image src={Duck} />
      </div>
    </WidthWrapper>
  );
};
