import { useTranslation } from 'react-i18next';
import { Typography } from '../../common/Typography/Typography';
import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './HeaderSection.module.scss';
import { Button } from '../../common/Button/Button';
import { Calculator } from './Calculator/Calculator';

export const HeaderSection = () => {
  const { t } = useTranslation();
  return (
    <WidthWrapper>
      <div className={styles.root}>
        <div className={styles.leftColumn}>
          <Typography
            variant="h1"
            align="left"
            alignMobile="center"
            className={styles.text}
            alignTablet="center"
          >
            {t('affiliatePage.header.title')}
          </Typography>
          <Typography color="var(--text2)" variant="pLargeRegular">
            {t('affiliatePage.header.subtitle')}
          </Typography>
          <Button
            title={t('common.button.joinNow')}
            size="large"
            href="http://app.impact.com/campaign-campaign-info-v2/Razz-Finanzz-AG.brand?io=sf3nNPqamGgQm%2FbMzJlGWG%2FEUqHP0wTCbHjwRzVXLNkCNGoMCb%2FvQaN1IytrjPFZ"
            target="_blank"
          />
        </div>
        <div className={styles.rightColumn}>
          <Calculator />
        </div>
      </div>
    </WidthWrapper>
  );
};
