import { FC, ReactNode } from 'react';
import styles from './WidthWrapper.module.scss';
import classNames from 'classnames';

interface IProps {
  children: ReactNode;
  center?: boolean;
  cropped?: boolean;
  grey?: boolean;
  className?: string;
}

export const WidthWrapper: FC<IProps> = ({ children, center, cropped, grey, className }) => {
  return (
    <div
      className={classNames(styles.root, className, {
        [styles.root__grey]: grey,
      })}
    >
      <div
        className={classNames(styles.content, {
          [styles.content__center]: center,
          [styles.content__cropped]: cropped,
        })}
      >
        {children}
      </div>
    </div>
  );
};
