import { ButtonHTMLAttributes, HTMLAttributeAnchorTarget, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';
import { Typography } from '../Typography/Typography';

type ButtonSubmitProps = {
  type: 'submit';
};

type ButtonTypeProps = {
  type?: 'text' | 'button' | 'icon';
  onClick: Required<ButtonHTMLAttributes<HTMLButtonElement>>['onClick'];
};

interface LinkTypeProps {
  type?: 'text' | 'button' | 'icon';
  href?: string;
  rel?: string;
  target?: HTMLAttributeAnchorTarget;
}

export type ButtonProps = (ButtonSubmitProps | ButtonTypeProps | LinkTypeProps) & {
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
  title?: string;
  color?: 'accent' | 'default' | 'white' | 'secondary' | 'grey';
  theme?: 'primary' | 'secondary' | 'black';
  size?: 'small' | 'medium' | 'large';
  fullwidth?: boolean;
};

export function Button({
  children,
  type = 'button',
  disabled = false,
  title,
  size = 'medium',
  theme = 'primary',
  fullwidth = false,
  ...props
}: ButtonProps) {
  const titleContent = useMemo(() => {
    if (!title) {
      return null;
    }
    switch (size) {
      case 'medium':
        return <Typography variant="pMediumMedium">{title}</Typography>;
      case 'large':
        return <Typography variant="pLargeMedium">{title}</Typography>;
      default:
        return <Typography variant="pLargeMedium">{title}</Typography>;
    }
  }, [title, size]);

  const className = classNames(
    styles.button,
    props.className,
    styles[`theme__${theme}`],
    styles[`${type}Type`],
    styles[`size__${size}`],
    {
      [styles.buttonDisabled]: disabled,
      [styles.fullwidth]: fullwidth,
    },
  );

  if ('href' in props) {
    return (
      <a className={className} {...props}>
        {titleContent}
        {!title && children}
      </a>
    );
  }

  if ('onClick' in props) {
    return (
      <button className={className} disabled={disabled} onClick={props.onClick} type="button">
        {titleContent}
        {!title && children}
      </button>
    );
  }

  return (
    <button
      className={className}
      disabled={disabled}
      type={type === 'submit' ? 'submit' : 'button'}
    >
      {titleContent}
      {!title && children}
    </button>
  );
}
