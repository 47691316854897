import { WidthWrapper } from '../../common/WidthWrapper/WidthWrapper';
import styles from './RewardReferralSection.module.scss';
import MiningTogether from './MiningTogether.png';
import { Typography } from '../../common/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from '../../common/Button/Button';

export const RewardReferralSection = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <WidthWrapper className={styles.widthWrapper}>
        <div className={styles.columns}>
          <div className={styles.leftColumn}>
            <div className={styles.text}>
              <Typography variant="h2" alignMobile="center">
                {t('indexPage.rewardReferralSection.title')}
              </Typography>
              <Typography variant="pLargeRegular" className={styles.subtitle} alignMobile="center">
                {t('indexPage.rewardReferralSection.subtitle')}
              </Typography>
            </div>
            <Button
              title={t('common.button.signUp')}
              size="large"
              href="https://app.adjust.com/qr3gjca"
              target="_blank"
            />
          </div>
          <div className={styles.rightColumn}>
            <img src={MiningTogether} alt="Referral" className={styles.img} />
          </div>
        </div>
      </WidthWrapper>
    </div>
  );
};
