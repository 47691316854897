/* eslint-disable import/no-default-export */
import { useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { RenderLayout } from '../layouts/RenderLayout/RenderLayout';

import { ReactComponent as CoinIcon } from '../images/Coin.svg';
import { ReactComponent as InvestIcon } from '../images/Invest.svg';
import { ReactComponent as WalletIcon } from '../images/Wallet.svg';
import { ReactComponent as SendIcon } from '../images/Send.svg';
import { ReactComponent as ReceiveIcon } from '../images/Receive.svg';
import { ReactComponent as TransactionIcon } from '../images/Transaction.svg';
import { ReactComponent as SecureIcon } from '../images/Secure.svg';
import { ReactComponent as CheckIcon } from '../images/Check.svg';
import { SeventhSection } from '../components/HomePage/SeventhSection/SeventhSection';
import { NinthSection } from '../components/HomePage/NinethSection/NinthSection';
import Image1 from '../images/graphics/MoneyCube.png';
import Image2 from '../images/graphics/Bee.png';
import Image3 from '../images/graphics/Postbox.png';
import Image4 from '../images/graphics/Portal.png';
import Image5 from '../images/indexPage.image5.png';
import Image8 from '../images/indexPage.image8.png';
import { SmallCardsSection } from '../components/HomePage/SmallCardsSection/SmallCardsSection';
import { UkModal } from '../components/HomePage/UkModal/UkModal';
import HomePageWithLocales from './HomePageWithLocales';

const HomePage = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage ?? '';

  useEffect(() => {
    document.title = 'Brighty app Financial and Earning app – See Your Money Grow';
  }, []);

  if (['ie', 'lt', 'lv', 'ro', 'pl'].includes(activeLocale)) {
    return <HomePageWithLocales />;
  }

  return (
    <>
      <RenderLayout
        top
        components={[
          {
            type: 'CENTERSECTION',
            title: t('indexPage.header.title'),
            subtitle: t('indexPage.header.subtitle'),
            imageSrc: Image1,
            withQR: true,
          },
          {
            type: 'CENTERSECTION',
            title: t('indexPage.section9.title'),
            subtitle: t('indexPage.section9.subtitle'),
            imageSrc: Image8,
            component: <SmallCardsSection />,
          },
          {
            type: 'RIGHTIMAGESECTION',
            title: t('indexPage.section1.title'),
            subtitle: t('indexPage.section1.subtitle'),
            caption: t('indexPage.section1.caption'),
            imageSrc: Image2,
            list: [
              {
                text: t('indexPage.section1.listElement1.title'),
                caption: t('indexPage.section1.listElement1.subtitle'),
                icon: <WalletIcon />,
              },
              {
                text: t('indexPage.section1.listElement2.title'),
                caption: t('indexPage.section1.listElement2.subtitle'),
                icon: <InvestIcon />,
              },
              {
                text: t('indexPage.section1.listElement3.title'),
                caption: t('indexPage.section1.listElement3.subtitle'),
                icon: <CoinIcon />,
              },
            ],
          },
          {
            type: 'LEFTIMAGESECTION',
            title: t('indexPage.section2.title'),
            caption: t('indexPage.section2.caption'),
            imageSrc: Image3,
            list: [
              {
                text: t('indexPage.section2.listElement1.title'),
                caption: t('indexPage.section2.listElement1.subtitle'),
                icon: <ReceiveIcon />,
              },
              {
                text: t('indexPage.section2.listElement2.title'),
                caption: t('indexPage.section2.listElement2.subtitle'),
                icon: <CoinIcon />,
              },
              {
                text: t('indexPage.section2.listElement3.title'),
                caption: t('indexPage.section2.listElement3.subtitle'),
                icon: <SendIcon />,
              },
              {
                text: t('indexPage.section2.listElement4.title'),
                caption: t('indexPage.section2.listElement4.subtitle'),
                icon: <WalletIcon />,
              },
            ],
          },
          {
            type: 'RIGHTIMAGESECTION',
            title: t('indexPage.section3.title'),
            subtitle: t('indexPage.section3.subtitle'),
            caption: t('indexPage.section3.caption'),
            imageSrc: Image4,
            list: [
              {
                text: t('indexPage.section3.listElement1.title'),
                caption: t('indexPage.section3.listElement1.subtitle'),
                icon: <TransactionIcon />,
              },
              {
                text: t('indexPage.section3.listElement2.title'),
                caption: t('indexPage.section3.listElement2.subtitle'),
                icon: <CheckIcon />,
              },
            ],
          },
          {
            type: 'CARDSSECTION',
            cards: [
              {
                title: t('indexPage.section4.card1.text'),
                icon: <WalletIcon />,
              },
              {
                title: t('indexPage.section4.card2.text'),
                icon: <CoinIcon />,
              },
              {
                title: t('indexPage.section4.card3.text'),
                icon: <TransactionIcon />,
              },
            ],
          },
          {
            type: 'CENTERSECTION',
            title: t('indexPage.section5.title'),
            subtitle: t('indexPage.section5.subtitle'),
            imageSrc: Image5,
          },
          // {
          //   type: 'LEFTIMAGESECTION',
          //   title: t('indexPage.section6.title'),
          //   caption: t('indexPage.section6.caption'),
          //   imageSrc: Image6,
          //   list: [
          //     {
          //       text: t('indexPage.section6.listElement1.title'),
          //       caption: t('indexPage.section6.listElement1.subtitle'),
          //       icon: <ProfileIcon />,
          //     },
          //     {
          //       text: t('indexPage.section6.listElement2.title'),
          //       caption: t('indexPage.section6.listElement2.subtitle'),
          //       icon: <CheckIcon />,
          //     },
          //     {
          //       text: t('indexPage.section6.listElement3.title'),
          //       caption: t('indexPage.section6.listElement3.subtitle'),
          //       icon: <CardIcon />,
          //     },
          //   ],
          // },
        ]}
      />
      <SeventhSection />
      <RenderLayout
        components={[
          {
            type: 'CARDSSECTION',
            title: t('indexPage.section8.title'),
            cards: [
              {
                title: t('indexPage.section8.card1.text'),
                link: {
                  title: t('indexPage.section8.card1.linkText'),
                  href: 'https://help.brighty.app/en/articles/6569855-what-are-stablecoins',
                },
                icon: <CoinIcon />,
              },
              {
                title: t('indexPage.section8.card2.text'),
                icon: <SecureIcon />,
                link: {
                  title: t('indexPage.section8.card2.linkText'),
                  href: 'https://help.brighty.app/en/articles/6572247-how-do-brighty-earning-vaults-work',
                },
              },
              {
                title: t('indexPage.section8.card3.text'),
                icon: <InvestIcon />,
                link: {
                  title: t('indexPage.section8.card3.linkText'),
                  href: 'https://help.brighty.app/en/articles/6730424-opening-brighty-earning-vaults',
                },
              },
            ],
          },
        ]}
      />
      <NinthSection />
      <UkModal />
    </>
  );
};

export default withTranslation()(HomePage);
